<template>
    <Layout>
        <v-card>
            <v-card-title>Add User</v-card-title>
            <v-card-text >
                <v-row class="pb-7">
                    <v-col cols="6">
                        <v-text-field v-model="user.name" label="Name" :error-messages="userCreationErrors.name_error"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="email" v-model="user.email" label="Email" :error-messages="userCreationErrors.email_error"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="password" v-model="user.password" label="Password" :error-messages="userCreationErrors.password_error"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="password" v-model="user.confirm_password" label="Confirm Password" :error-messages="userCreationErrors.confirm_password_error"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pb-7">
                    <v-col cols="12">
                        <v-card-actions class="pt-2 pb-5 d-flex justify-end">
                            <v-img :contain="true" :max-height="40" :src="loader" :position="'top center'" v-show="loading">
                            </v-img>
                            <div v-if="add_success">
                                <v-alert type="success" class="mb-0 user-success-alert">
                                    User created successfully
                                </v-alert>
                            </div>
                            <v-btn large class="px-8 py-2 ml-8 white--text" color="blue-grey" v-if="!currentUserID" @click="addNewUser">Add User</v-btn>
                            <v-btn large class="px-8 py-2 ml-8 white--text" color="blue-grey" v-if="currentUserID"  @click="updateUser">Update User</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </Layout>
</template>

<script>
import Layout from "@/shared/Layout";
import loader from "@/img/filter-loader.gif";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            tab: 0,
            user: this.defaultUserFormData(),
            loader: loader,
            loading: false,
            add_success: false
        }
    },
    created() {
        this.userDataPull();
    },
    computed: {
        currentUserID(){
            return this.$store.getters['user/getUserID'];
        },
        userCreationErrors(){
            var errors = this.$store.getters['user/getErrors'];
            var error_data = {};

            if (typeof errors.errors == 'undefined') {
                return error_data;
            }

            error_data['name_error'] = typeof errors.errors.name != 'undefined' ? errors.errors.name[0] : '';
            error_data['email_error'] = typeof errors.errors.email != 'undefined' ? errors.errors.email[0] : '';
            error_data['password_error'] = typeof errors.errors.password != 'undefined' ? errors.errors.password[0] : '';
            error_data['confirm_password_error'] = typeof errors.errors.confirm_password != 'undefined' ? errors.errors.confirm_password[0] : '';

            return error_data;
        }
    },
    watch: {
        '$route' (to, from) {
            this.userDataPull();
        }
    },
    methods: {
        addNewUser(){
            var self = this;
            var new_user = this.user;
            var user_arguments = {
                name: new_user.name,
                email: new_user.email,
                password: new_user.password,
                confirm_password: new_user.confirm_password,
            }
            self.loading = true;
            this.$store.dispatch('user/addNewUser', user_arguments).then((data)=>{
                self.loading = false;
                self.add_success = true;
                setTimeout(function(){
                    self.add_success = false;
                }, 3000);
            })
            .catch((response)=> {
                self.loading = false;
            });
        },
        setUser(user){
            this.user.name = user.name;
            this.user.email = user.email;
        },
        setDefaultUserData() {
            this.user = this.defaultUserFormData();
        },
        updateUser(){
            var self = this;
            var old_user = this.user;
            var user_arguments = {
                user_id: this.currentUserID,
                name: old_user.name,
                email: old_user.email,
                password: old_user.password,
                confirm_password: old_user.confirm_password,
            }
            self.loading = true;
            this.$store.dispatch('user/updateUser', user_arguments).then((data)=>{
                self.loading = false;
                self.add_success = true;
                setTimeout(function(){
                    self.add_success = false;
                }, 3000);
            })
            .catch((response)=> {
                self.loading = false;
            });
        },
        userDataPull(){
            if(typeof this.$route.params.user_id != 'undefined'){
                this.$store.dispatch('user/fetchUser', this.$route.params.user_id).then((data)=>{
                    if (data.success) {
                        this.setUser(data.success);
                    }
                })
                .catch((response)=> {
                    // console.log(response);
                });
            } else {
                this.$store.dispatch('user/resetUserID');
                this.setDefaultUserData();
            }
        },
        defaultUserFormData(){
            return {
                name: '',
                email: '',
                password: '',
                confirm_password: '',
            }
        }
    }
}
</script>

<style>
.user-success-alert {
    padding: 10px 16px;
}
</style>