<template>
  <v-card color="basil" flat>
    <v-card class="d-flex justify-space-between align-center px-4 py-4" color="lighten-2" flat >
      <v-toolbar-title>Background &amp; Objectives</v-toolbar-title>
    </v-card>
    <v-card-text>
      <v-container>
         <v-row>
          <v-col cols="12">
            <div class="comment">
              <div class="left_part">
                <h3 class="edit_label">About the document</h3><span class="approved_label">Approved</span>
              </div>
              <div class="right_part">
                <div class="badges active">
                  <i class="fa fa-comments"></i><span class="badge">1</span>
                </div>
                <button type="button" class="outline_btn">
                Add Comment
                </button>
              </div>
            </div>
            <!-- <h3 class="editor-label">About the document</h3> -->
            <ckeditor v-model="backgroundAbout" :config="editorConfig"></ckeditor>
            <div class="editor-error-message" v-if="backgroundError.about"><p>{{ backgroundError.about }}</p></div>
          </v-col>
         </v-row>
         <v-row>
          <v-col cols="12">
            <!-- <h3 class="editor-label">Executive summery</h3> -->
            <div class="comment">
              <div class="left_part">
                <h3 class="edit_label">Executive summery</h3><span class="draft_label">Draft</span>
              </div>
              <div class="right_part">
                <div class="badges">
                  <i class="fa fa-comments"></i><span class="badge"></span>
                </div>
                <button type="button" class="outline_btn">
                Add Comment
                </button>
              </div>
            </div>
            <ckeditor v-model="backgroundSummary" :config="editorConfig"></ckeditor>
            <div class="editor-error-message" v-if="backgroundError.summary"><p>{{ backgroundError.summary }}</p></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- <h3 class="editor-label">Objectives</h3> -->
             <div class="comment">
              <div class="left_part">
                <h3 class="edit_label">Objectives</h3><span class="draft_label">Draft</span>
              </div>
              <div class="right_part">
                <div class="badges">
                  <i class="fa fa-comments"></i><span class="badge"></span>
                </div>
                <button type="button" class="outline_btn">
                Add Comment
                </button>
              </div>
            </div>
            <ckeditor v-model="backgroundObjective" :config="editorConfig"></ckeditor>
          </v-col>
        </v-row>
        <div class="d-flex justify-flex-start align-center py-3" flat >
          <div class="text-left">
            <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDocumentOverview">Save</v-btn>
          </div>
          <div class="success-message" v-if="saveSuccess">
            <p>Data Updated Successfully</p>
          </div>
        </div>
     
      </v-container>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapState } from 'vuex';
import { CKEDITOR_CONFIG } from '../../../common/config';
export default {
  data() {
    return {
      saveSuccess: false,
      editorConfig: CKEDITOR_CONFIG
    };
  },
  mounted() {
    var scope_id = this.$store.getters['scope/getScopeID'];
    var self = this;
    this.$store.dispatch('documentOverview/fetchScopeOverview', scope_id).then((data)=>{
      
    })
    .catch((response)=> {
        // console.log(response);
    });
  },
  computed: {
    ...mapState('documentOverview', ['scope_doc']),
    currentScopeID(){
        return this.$store.getters['scope/getScopeID'];
    },
    backgroundError(){
      var errors = this.$store.getters['documentOverview/getErrors'];
      var error_data = {};

      if (typeof errors.errors == 'undefined') {
        return error_data;
      }
      
      error_data['about'] = typeof errors.errors.about != 'undefined' ? errors.errors.about[0] : '';
      error_data['summary'] = typeof errors.errors.summary != 'undefined' ? errors.errors.summary[0] : '';
      return error_data;
    },
    backgroundAbout: {
      get(){
        return this.scope_doc.about;
      },
      set(about){
        this.$store.commit('documentOverview/setScopeBackgroundField', {key: 'about', val: about});
      }
    },
    backgroundSummary: {
      get(){
        return this.scope_doc.summary;
      },
      set(summary){
        this.$store.commit('documentOverview/setScopeBackgroundField', {key: 'summary', val: summary});
      }
    },
    backgroundObjective: {
      get(){
        return this.scope_doc.objective;
      },
      set(objective){
        this.$store.commit('documentOverview/setScopeBackgroundField', {key: 'objective', val: objective});
      }
    }
  },
  methods: {
    saveDocumentOverview(){
      this.saveSuccess = false;
      var docOverviewData = this.scope_doc;
      var background_overview = {
        about: docOverviewData.about,
        summary: docOverviewData.summary,
        objective: docOverviewData.objective,
        scope_id: this.currentScopeID,
      };
      this.$store.dispatch('documentOverview/addNewDocumentOverview', background_overview).then((data)=>{
        this.saveSuccess = true;    
      })
      .catch((response)=> {
        // console.log(response);
      });
    }
  }
}
</script>

<style>
.editor-label {
  margin-bottom: 15px;
  color: #000;
  font-weight: 400;
}
.editor-error-message {
  margin: 15px 0;
  color: #ff5252;
  font-size: 16px;
}
.success-message {
  color: green;
  font-size: 16px;
}
.success-message p {
  margin: 0;
  padding: 0 20px 0 30px;
}
</style>