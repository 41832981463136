import {TOKEN_KEY, TOKEN_REFRESH_KEY, TOKEN_REFRESH_EXPIRE, TOKEN_ROUTE, API_URL} from "./config";
import VueCookies from 'vue-cookies';
import JwtDecode from "jwt-decode";
import VueAxios from "vue-axios";
import store from '../store';
import axios from "axios";
import Vue from "vue";

Vue.use(VueAxios, axios);

const isExpiredToken = (token) => {
    try {
        let tokenDecoded = JwtDecode(token);

        if (typeof tokenDecoded.exp != 'undefined' && Date.now() < (tokenDecoded.exp * 1000)) {
            return false;
        }
    } catch (exception) {
        // console.log(exception.name);
        // console.log(exception.message);
    }

    return true;
};

const requestToken = async () => {
    let config = {
        refresh_token: VueCookies.get(TOKEN_REFRESH_KEY)
    };

    if (!!config.refresh_token) {
        await Vue.axios.post(`${API_URL}${TOKEN_ROUTE}`, config).then((res) => {
            if (res.status == 200) {
                setToken(res.data.success.access_token);
                setRefreshToken(res.data.success.refresh_token);
            }
        })
        .catch(({response}) => {
            // console.log(response);
        });
    } else {
        store.dispatch('authentication/logout');
    }
};

const getToken = async () => {
    let token = window.localStorage.getItem(TOKEN_KEY);

    if (!!token && isExpiredToken(token)) {
        await requestToken();
        token = window.localStorage.getItem(TOKEN_KEY);
    }

    return token;
};

const authCheck = () => {
    let token = window.localStorage.getItem(TOKEN_KEY);

    return !!token;
};

const setToken = (token) => {
    window.localStorage.setItem(TOKEN_KEY, token);
};

const setRefreshToken = (token) => {
    VueCookies.set(TOKEN_REFRESH_KEY, token, TOKEN_REFRESH_EXPIRE);
};

const unsetToken = () => {
    window.localStorage.removeItem(TOKEN_KEY);
};

const unsetRefreshToken = () => {
    VueCookies.remove(TOKEN_REFRESH_KEY);
};

export default {
    getToken, 
    setToken, 
    authCheck, 
    unsetToken,
    setRefreshToken,
    unsetRefreshToken
}