<template>
    <Layout>
        <v-card>
            <v-alert type="warning" v-if="clientNotFound">
                The client you trying to edit can't be found. Please add a new client.
            </v-alert>
            <v-card-title>Add Client</v-card-title>
            <v-card-text >
                <v-row class="pb-7">
                    <v-col cols="6">
                        <v-text-field v-model="clientName" label="Name" :error-messages="clientCreationErrors.name_error"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="clientCompanyName" label="Company Name" :error-messages="clientCreationErrors.company_name"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="clientAddress" label="Address" :error-messages="clientCreationErrors.address"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pb-7">
                    <v-col cols="12">
                        <v-card-actions class="pt-2 pb-5 d-flex justify-end">
                            <v-img :contain="true" :max-height="40" :src="loader" :position="'top center'" v-show="loading">
                            </v-img>
                            <div v-if="add_success">
                                <v-alert type="success" class="mb-0 user-success-alert">
                                    Client created successfully
                                </v-alert>
                            </div>
                            <v-btn large class="px-8 py-2 ml-8 white--text" color="blue-grey" v-if="!currentClientID" @click="addNewClient">Add Client</v-btn>
                            <v-btn large class="px-8 py-2 ml-8 white--text" color="blue-grey" v-if="currentClientID" @click="updateClient">Update Client</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </Layout>
</template>

<script>
import { mapState } from 'vuex';
import Layout from "@/shared/Layout";
import loader from "@/img/filter-loader.gif";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            tab: 0,
            loader: loader,
            loading: false,
            add_success: false,
            clientNotFound: false
        }
    },
    mounted() {
        if(typeof this.$route.params.client_id != 'undefined'){
            this.$store.dispatch('client/fetchClient', this.$route.params.client_id).then((data)=>{
                // console.log(this.$route.params.client_id, data);
            })
            .catch((response)=> {
                this.clientNotFound = true;
            });
        } else {
            this.$store.commit('client/resetClient');
        }
    },
    computed: {
        ...mapState('client', ['clientData']),
        currentClientID(){
            return this.clientData.client_id;
        },
        clientCreationErrors(){
            var errors = this.$store.getters['client/getErrors'];
            var error_data = {};

            if (typeof errors.errors == 'undefined') {
                return error_data;
            }

            error_data['name_error'] = typeof errors.errors.name != 'undefined' ? errors.errors.name[0] : '';
            error_data['company_name'] = typeof errors.errors.company_name != 'undefined' ? errors.errors.company_name[0] : '';
            error_data['address'] = typeof errors.errors.address != 'undefined' ? errors.errors.address[0] : '';

            return error_data;
        },
        clientName: {
            get(){
                return this.clientData.name;
            },
            set(clientName){
                var client = {                    
                    name: clientName,
                    address: this.clientData.address,
                    client_id: this.currentClientID,
                    company_name: this.clientData.company_name
                };
                
                this.$store.commit('client/setClient', client);
            }
		},
        clientCompanyName: {
            get(){
                return this.clientData.company_name;
            },
            set(clientCompanyName){
                var client = {                    
                    name: this.clientData.name,
                    address: this.clientData.address,
                    client_id: this.currentClientID,
                    company_name: clientCompanyName
                };
                
                this.$store.commit('client/setClient', client);
            }
		},
        clientAddress: {
            get(){
                return this.clientData.address;
            },
            set(clientAddress){
                var client = {                    
                    address: clientAddress,
                    name: this.clientData.name,
                    client_id: this.currentClientID,
                    company_name: this.clientData.company_name
                };
                
                this.$store.commit('client/setClient', client);
            }
		},
    },
    watch: {
        '$route' (to, from) {
            this.$store.commit('client/resetClient');
        }
    },
    methods: {
        addNewClient(){
            var self = this;
            var client_arguments = {
                name: this.clientName,
                address: this.clientAddress,
                company_name: this.clientCompanyName,
            }
            self.loading = true;
            this.$store.dispatch('client/addNewClient', client_arguments).then((data)=>{
                self.loading = false;
                self.add_success = true;
                
                setTimeout(function(){
                    self.add_success = false;
                }, 3000);
            })
            .catch((response)=> {
                self.loading = false;
            });
        },
        updateClient(){
            var self = this;
            var client_arguments = {
                name: this.clientName,
                address: this.clientAddress,
                client_id: this.currentClientID,
                company_name: this.clientCompanyName,
            }
            self.loading = true;
            this.$store.dispatch('client/updateClient', client_arguments).then((data)=>{
                self.loading = false;
                self.add_success = true;

                setTimeout(function(){
                    self.add_success = false;
                }, 3000);
            })
            .catch((response)=> {
                self.loading = false;
            });
        },
    }
}
</script>