import Errors from '../form';
import ApiService from '../api/api.service';
import {COMPONENT_TYPES} from '../common/config';

const getDefaultAppendix = () => {
    return {
        dt_id: 0,
        appendix: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <p>&nbsp;</p><p>Glossary &amp; Acronyms</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p><p>&nbsp;</p>
        <p>References -</p><p>&nbsp;</p><ul><li><p><a href="https://docs.google.com/document/d/106-nUpeChv0x07yO9H6ybGCtRx7vkh5r1BWVVS1BBNA/edit?ts=5e2ec8a1#">
        https://docs.google.com/document/d/106-nUpeChv0x07yO9H6ybGCtRx7vkh5r1BWVVS1BBNA/edit?ts=5e2ec8a1#</a></p></li><li><p>
        <a href="https://docs.google.com/document/d/1LFluCPn5C-J2zwIhpfXiZg_o305kx8D1kFUiup-X_eU/edit">
        https://docs.google.com/document/d/1LFluCPn5C-J2zwIhpfXiZg_o305kx8D1kFUiup-X_eU/edit</a></p></li></ul><p>&nbsp;</p><p>
        Budget &amp; Payment Terms</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p><ul><li>
        <p>One-time License</p></li><li><p>Subscription Billing</p></li></ul><p>&nbsp;</p><p>Hosting &amp; Maintenance Contract</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p><p>&nbsp;</p><p>References -</p><p>&nbsp;</p>
        <ul><li><p><a href="https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#">
        https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#</a></p></li></ul><p>&nbsp;</p><p>
        Platform Stack &amp; Architecture</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <p>&nbsp;</p><p>References -</p><p>&nbsp;</p><ul><li><p><a href="https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#">
        https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#</a></p></li></ul><p>&nbsp;</p>
        <p>Platform Terms &amp; Condition Document</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <p>&nbsp;</p><p>References -</p><p>&nbsp;</p><ul><li><p><a href="https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#">
        https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#</a></p></li><li><p>
        <a href="https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#">
        https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#</a></p></li></ul><p>&nbsp;</p>
        <p>Additional Contractual Terms</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p><p>&nbsp;</p>
        <p>References -</p><p>&nbsp;</p><ul><li><p><a href="https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#">
        https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#</a></p></li><li><p>
        <a href="https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#">
        https://docs.google.com/document/d/1c6zfSz6RIyJ4_z3zHHp8eUxr3bG26yEueLsR18_qgss/edit#</a></p></li></ul><p>&nbsp;</p><p>Vendor Proposal Requirements</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>`
    }
};

const state = {
    errors: new Errors(),
    product_details: [],
    existing_product_details: [],
    appendix_data: getDefaultAppendix()
};

const getters = {
    getErrors(state){
        return state.errors;
    },
    getScopeAppendix(state){
        return state.appendix_data;
    },
    getExistingProductDetails(state) {
        return state.existing_product_details;
    },
    getProductDetails(state){
        return state.product_details;
    }
};

const mutations = {
    setError(state, {target, message}) {
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setScopeAppendix(state, appendix_data){
        if(appendix_data){
            state.appendix_data = appendix_data;
        } else {
            state.appendix_data = getDefaultAppendix();
        }
    },
    setAppendixData(state, appendix){
        state.appendix_data.appendix = appendix;
    },
    setExistingProductDetails(state, productDetails) {
        state.existing_product_details = productDetails;
    },
    setProductDetails(state, productDetails) {
        state.product_details = productDetails;
    },
    addProductDetails(state, productDetails) {
        state.product_details.push(productDetails);
    },
    updateProductDetails(state, productDetails) {
        var productDetailsIndex = state.product_details.findIndex((productDetail => productDetail.pd_id == productDetails.pd_id));

        state.product_details.splice(productDetailsIndex, 1, productDetails);
    },
    removeProductDetails(state, productDetailsId) {
        state.product_details = state.product_details.filter(productDetails => productDetails.pd_id !== productDetailsId);
    }
};

const actions = {
    saveAppendix(context, appendix_arg){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/appendix-save', {...appendix_arg})
            .then(({data})=>{
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'appendix', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchAppendixData(context, scope_id){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/appendix-data', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setScopeAppendix', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    fetchExistingProductDetailsData(context) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/component-list', `?type=${COMPONENT_TYPES[1]}`).then(({data}) => {
                context.commit('setExistingProductDetails', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    fetchProductDetailsData(context, scope_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/product-details', `?scope_id=${scope_id}`).then(({data}) => {
                context.commit('setProductDetails', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    addProductDetails(context, productDetails) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/product-details-save', {...productDetails})
            .then(({data})=>{
                context.commit('clearErrors');

                context.commit('addProductDetails', data.success);
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'productDetails', message: response.data.error});
                reject(response);
            });
        });
    },
    editProductDetails(context, productDetails) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/product-details-save', {...productDetails})
            .then(({data})=>{
                context.commit('clearErrors');

                context.commit('updateProductDetails', data.success);
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'productDetails', message: response.data.error});
                reject(response);
            });
        });
    },
    deleteProductDetails(context, productDetailsId) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/product-details-delete', {pd_id: productDetailsId})
            .then(({data})=>{
                context.commit('removeProductDetails', productDetailsId);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};