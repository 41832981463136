<template>
	<div class="all-versions">
		<v-card color="basil" flat>
			<v-card class="d-flex justify-space-between align-center px-4 py-4" color="lighten-2" flat >
				<v-toolbar-title>All Versions</v-toolbar-title>
			</v-card>

			<v-card-text>
				<v-simple-table>
					<template>
						<v-data-table :headers="headers" :items="scope_versions" item-key="version_id">
							<template v-slot:top>
								<v-dialog v-model="dialogRestoreScope" max-width="500px">
									<v-card>
										<v-card-title>Are you sure want to restore to this version?</v-card-title>
										<v-card-actions>
											<v-spacer></v-spacer>
											<v-btn color="blue darken-1" text @click="closeRestoreScope">No</v-btn>
											<v-btn color="blue darken-1" text @click="RestoreScopeConfirm">Yes</v-btn>
											<v-spacer></v-spacer>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</template>
							<template v-slot:item.actions="{item}">
								<v-btn class="ma-2" small outlined color="primary" @click="restoreScopePerform(item.version_id)">
									<v-icon small left>mdi-arrow-down-bold</v-icon> Restore version
								</v-btn>
							</template>
						</v-data-table>
					</template>
				</v-simple-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
			dialogRestoreScope: false,
			selectedVersion: 0,
			headers: [
				{
					text: 'Version time',
					align: 'start',
					sortable: true,
					value: 'created_at',
				},
				{
					text: 'Actions', 
					value: 'actions', 
					sortable: false
				},
			]
        };
    },
    mounted() {
		this.fetchCurrentVersions();
    },
    computed: {
        ...mapState('version', ['scope_versions']),
        currentScopeID(){
            return this.$store.getters['scope/getScopeID'];
		}
	},
	watch: {
		dialogRestoreScope(val) {
			val || this.closeRestoreScope()
		}
    },
    methods: {
		restoreScopeVersion(versionId){
			var that = this;
			this.$store.dispatch('version/restoreScopeVersion', {version_id: versionId}).then((data)=>{
				that.$router.go();
				// console.log(data.success);
			})
			.catch((response)=> {
				that.$router.go();
				// console.log(response);
			});
		},
		fetchCurrentVersions(){
			var scope_id = this.currentScopeID;

			this.$store.dispatch('version/fetchScopeVersions', scope_id).then((data)=>{
				// console.log(data.success);
			})
			.catch((response)=> {
				// console.log(response);
			});
		},
		restoreScopePerform(version_id){
			this.dialogRestoreScope = true;
			this.selectedVersion = version_id;
		},
		RestoreScopeConfirm () {
			this.restoreScopeVersion(this.selectedVersion);
			this.closeRestoreScope();
		},
		closeRestoreScope () {
			this.dialogRestoreScope = false;
			this.$nextTick(() => {
				this.selectedVersion = 0;
			});
		},
    }
}
</script>

