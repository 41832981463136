<template>
    <div class="epic-list-area">
        <div class="epic-list-container" v-if="epic_data.get_epic.length">
            <v-expansion-panels>
              <v-expansion-panel class="mb-7 epic-item" v-for="(epic, epic_index) in epic_data.get_epic" :key="epic.epic_id">
                <v-expansion-panel-header class="pb-5">{{ epic_index + 1 }}. {{ epic.title }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field label="Title" v-model="epic.title" readonly="readonly" @click="setEpicEdit(epic)"></v-text-field>
                  <h3 class="editor-label">Description</h3>
                  <div class="editor-view" @click="setEpicEdit(epic)" v-html="epic.description"></div>
                  <div class="pb-7 epic-story-list">
                    <v-toolbar-title class="pb-5">Story</v-toolbar-title>
                    <v-card-text class="px-0 py-0">
                      <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Features</th>
                                <th class="text-left">Actor</th>
                                <th class="text-left">Details</th>
                                <th class="text-left">Dependency</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(breakdown, breakdown_index) in epic.epic_module" :key="breakdown.epb_id">
                                <td>{{ breakdown.feature }}</td>
                                <td>{{ getActorName(breakdown.actor_id) }}</td>
                                <td>{{ breakdown.details }}</td>
                                <td>{{ breakdown.dependency }}</td>
                                <td>
                                  <v-btn class="ma-2" small outlined color="error" @click="deleteBreakdown(epic.epic_id, epic_index, breakdown.epb_id, breakdown_index)">
                                    <v-icon small left>mdi-delete</v-icon> Remove
                                  </v-btn>
                                  <v-btn class="ma-2" small outlined color="primary" @click="editBreakdownDialog(epic.epic_id, epic_index, breakdown.epb_id, breakdown_index)">
                                    <v-icon small left>mdi-pencil</v-icon> edit
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                      </v-simple-table>
                      <div class="text-right">
                        <v-btn class="ma-2 white--text mt-4" color="blue-grey" @click="setEpicNumber(epic.epic_id, epic_index)">
                          <v-icon left>mdi-plus</v-icon> Add more
                        </v-btn>
                      </div>
                    </v-card-text>
                  </div>

                  <div class="epic-additional-input">
                    <h3 class="editor-label">Out of Scope</h3>
                    <div class="editor-view" @click="setEpicEdit(epic)" v-html="epic.out_scope"></div>
                    <h3 class="editor-label">Assumption and Dependencies</h3>
                    <div class="editor-view" @click="setEpicEdit(epic)" v-html="epic.assumption"></div>
                    <h3 class="editor-label">Risks</h3>
                    <div class="editor-view" @click="setEpicEdit(epic)" v-html="epic.risks"></div>
                    <div class="text-right">
                      <v-btn class="ma-2 mt-4" small outlined color="error" @click="deleteEpicData(epic.epic_id)">
                        <v-icon small left>mdi-delete</v-icon> Remove
                      </v-btn>
                      <v-btn class="ma-2 mt-4" small outlined color="primary" @click="setEpicEdit(epic)">
                        <v-icon small left>mdi-pencil</v-icon> Edit
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="mb-7">
              <v-toolbar-title class="pb-5">{{ epic_data.get_epic.length + 1 }}.Epic</v-toolbar-title>
              <v-card-actions>
                <div class="text-left" v-if="!epic_adding">
                  <v-btn large class="px-8 py-2 white--text mt-4" color="blue-grey" @click="epic_adding = true">Add New Epic</v-btn>
                </div>
                <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>
                <div class="text-left" v-if="!epic_adding">
                  <v-btn large class="px-8 py-2 white--text mt-4" color="blue-grey" @click="existing_epic_dialog = true">Reuse Existing Epic</v-btn>
                </div>
              </v-card-actions>
              <div class="new-epic-block" v-if="epic_adding">
                <v-text-field label="Title" v-model="epicAddData.title" :error-messages="epicCreationErrors.title"></v-text-field>
                <!-- <v-text-field label="Description" v-model="epicAddData.description"></v-text-field> -->
                <h3 class="editor-label">Description</h3>
                <ckeditor v-model="epicAddData.description" :config="editorConfig"></ckeditor>
                <div class="text-left">
                  <v-btn large class="px-8 py-2 white--text mt-4" color="blue-grey" @click="saveEpic()">Save Epic</v-btn>
                </div>
              </div>
            </div>
        </div>

        <div class="epic-list-container" v-else>
            <div class="mb-7">
              <v-toolbar-title class="pb-5">1.Epic</v-toolbar-title>
              <v-text-field label="Title" v-model="epicAddData.title" :error-messages="epicCreationErrors.title"></v-text-field>
              <!-- <v-text-field label="Description" v-model="epicAddData.description"></v-text-field> -->
              <h3 class="editor-label">Description</h3>
              <ckeditor v-model="epicAddData.description" :config="editorConfig"></ckeditor>
              <v-card-actions>
                <div class="text-left">
                  <v-btn large class="px-8 py-2 white--text mt-4" color="blue-grey" @click="saveEpic()">Add Epic</v-btn>
                </div>
                <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>
                <div class="text-left" v-if="!epic_adding">
                  <v-btn large class="px-8 py-2 white--text mt-4" color="blue-grey" @click="existing_epic_dialog = true">Reuse Existing Epic</v-btn>
                </div>
              </v-card-actions>
            </div>
        </div>

        <v-dialog v-model="epic_editing" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Epic {{ epicEditData.title }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Title*" v-model="epicEditData.title" :error-messages="errors.epicEditError.title"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3 class="editor-label">Description</h3>
                    <ckeditor v-model="epicEditData.description" :config="editorConfig"></ckeditor>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3 class="editor-label">Out Scope</h3>
                    <ckeditor v-model="epicEditData.out_scope" :config="editorConfig"></ckeditor>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3 class="editor-label">Assumption</h3>
                    <ckeditor v-model="epicEditData.assumption" :config="editorConfig"></ckeditor>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3 class="editor-label">Risks</h3>
                    <ckeditor v-model="epicEditData.risks" :config="editorConfig"></ckeditor>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEpicEditing()">Close</v-btn>
              <v-btn color="blue darken-1" text @click="saveEpicEdit()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="epic_breakdown_dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline"> {{ epicBreakdownAdd.breakdown_edit_id ? 'Edit breakdown' : 'Add new breakdown' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Feature*" v-model="epicBreakdownAdd.feature" :error-messages="breakdownCreationErrors.feature" required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select label="Actor*" v-model="epicBreakdownAdd.actor_id" :items="allActorList" :error-messages="breakdownCreationErrors.actor_id"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Details" v-model="epicBreakdownAdd.details"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Dependency*" v-model="epicBreakdownAdd.dependency" required :error-messages="breakdownCreationErrors.dependency"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEpicBreakdownDialog()">Close</v-btn>
              <v-btn color="blue darken-1" text @click="saveEpicBreakdown()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="existing_epic_dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Select Existing Epic</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete v-model="selectedExistingEpic" :items="existing_epics" filled label="Select Epic" item-text="title" item-value="id"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="existing_epic_dialog = false; selectedExistingEpic = 0">Close</v-btn>
              <v-btn color="blue darken-1" text @click="addExistingEpic(selectedExistingEpic)">Add</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { CKEDITOR_CONFIG, DEFAULT_ACTOR } from '../../../common/config';

export default {
    data() {
      return {
          epic_breakdown_dialog: false,
          existing_epic_dialog: false,
          epic_editing: false,
          epicAddData: this.defaultEpicData(),
          epicEditData: this.defaultEpicEditDialog(),
          epicBreakdownAdd: this.defaultEpicBreakdown(),
          errors: {
            epicError: '',
            epic_break_error: '',
            epicEditError: ''
          },
          epic_adding: false,
          editorConfig: CKEDITOR_CONFIG,
          selectedExistingEpic: 0,
          defaultActor: DEFAULT_ACTOR
      }  
    },
    computed: {
        ...mapState('epicScope', ['epic_data', 'existing_epics']),
        currentScopeID(){
            return this.$store.getters['scope/getScopeID'];
        },
        scopeActors(){
            return this.$store.getters['epicScope/getScopeActors'];
        },
        epicCreationErrors(){
            var error_data = {};
            if (typeof this.errors.epicError == 'undefined') {
              return error_data;
            }

            error_data['title'] = typeof this.errors.epicError.title != 'undefined' ? this.errors.epicError.title[0] : '';
            return error_data;
        },
        breakdownCreationErrors(){
            var error_data = {};
            if (typeof this.errors.epic_break_error == 'undefined') {
              return error_data;
            }

            error_data['feature'] = typeof this.errors.epic_break_error.feature != 'undefined' ? this.errors.epic_break_error.feature[0] : '';
            error_data['dependency'] = typeof this.errors.epic_break_error.dependency != 'undefined' ? this.errors.epic_break_error.dependency[0] : '';
            error_data['actor_id'] = typeof this.errors.epic_break_error.actor_id != 'undefined' ? this.errors.epic_break_error.actor_id[0] : '';
            return error_data;
        },
        allActorList(){
            var actorList = [];
            var fullActorList = this.epic_data.epic_table;
            for (var actor in fullActorList) {
                var actor_item = {};
                actor_item['value'] = fullActorList[actor].actor_id;
                actor_item['text'] = fullActorList[actor].actor_name;
                actorList.push(actor_item);
            }
            return actorList;
        }
    },
    methods: {
        getActorName(actor_id){
          var selected_actor = this.epic_data.epic_table.filter(function (actor) {
              return actor.actor_id == actor_id;
          });
          return selected_actor[0].actor_name;
        },
        defaultEpicData(){
          return {
              title: '',
              description: ''
          };
        },
        saveEpic(){
          this.errors.epicError = '';
          var epic_add_data = {
            scope_id: this.currentScopeID,
            title: this.epicAddData.title,
            description: this.epicAddData.description
          };
          var that = this;
          return new Promise(function(resolve, reject) {
            that.$store.dispatch('epicScope/saveEpic', epic_add_data).then((data)=>{
              that.epicAddData = that.defaultEpicData();
              that.epic_adding = false;
              that.$store.commit('planning/addNewPricingBreakdown', {breakdown_data: data.price_break, epic_data: data.success});
              resolve(data);
            })
            .catch((response)=> {
                // console.log(response);
                that.errors.epicError = response.data.error;
                reject(response);
            });
          });
        },
        defaultEpicBreakdown(){
          return {
              epb_id: 0,
              epic_index: '',
              breakdown_index: '',
              epic_id: '',
              actor_id: '',
              feature: '',
              details: '',
              dependency: ''
          };
        },
        defaultEpicEditDialog(){
          return {
              epic_id: 0,
              title: '',
              description: '',
              out_scope: '',
              assumption: '',
              risks: ''
          };
        },
        setEpicNumber(epic, index){
          this.epic_breakdown_dialog = true;
          this.epicBreakdownAdd.epic_id = epic;
          this.epicBreakdownAdd.epic_index = index;
        },
        setEpicEdit(epic){
          this.epicEditData.epic_id = epic.epic_id;
          this.epicEditData.title = epic.title;
          this.epicEditData.description = epic.description;
          this.epicEditData.out_scope = epic.out_scope;
          this.epicEditData.assumption = epic.assumption;
          this.epicEditData.risks = epic.risks;
          if (typeof epic.autoAssign != 'undefined' && epic.autoAssign) {
            this.epic_editing = false;
          } else {
            this.epic_editing = true;
          }
        },
        closeBreakdownDialog(){
          this.epic_breakdown_dialog = false;
          this.epicBreakdownAdd = this.defaultEpicBreakdown();
          this.errors.epic_break_error = '';
        },
        closeEpicEditing(){
          this.epic_editing = false;
          this.epicEditData = this.defaultEpicEditDialog();
          this.errors.epicEditError = '';
        },
        closeEpicBreakdownDialog(){
          this.epic_breakdown_dialog = false;
          this.epicBreakdownAdd = this.defaultEpicBreakdown();
          this.errors.epic_break_error = '';
        },
        editBreakdownDialog(epic_id, epic_index, breakdown_id, breakdown_index){
          var epic_list = this.epic_data.get_epic;
          this.epicBreakdownAdd.breakdown_edit_id = breakdown_id;
          this.epicBreakdownAdd.epic_id = epic_id;
          this.epicBreakdownAdd.actor_id = epic_list[epic_index].epic_module[breakdown_index].actor_id;
          this.epicBreakdownAdd.feature = epic_list[epic_index].epic_module[breakdown_index].feature;
          this.epicBreakdownAdd.dependency = epic_list[epic_index].epic_module[breakdown_index].dependency;
          this.epicBreakdownAdd.details = epic_list[epic_index].epic_module[breakdown_index].details;
          this.epic_breakdown_dialog = true;

          this.epicBreakdownAdd.epic_index = epic_index;
          this.epicBreakdownAdd.breakdown_index = breakdown_index;
        },
        saveEpicBreakdown(){
          this.errors.epic_break_error = '';
          var epic_break_data = {
              scope_id: this.currentScopeID,
              epic_id: this.epicBreakdownAdd.epic_id,
              actor_id: this.epicBreakdownAdd.actor_id,
              feature: this.epicBreakdownAdd.feature,
              dependency: this.epicBreakdownAdd.dependency,
              details: this.epicBreakdownAdd.details,
          };
          var breakdown_edit_id = this.epicBreakdownAdd.breakdown_edit_id;
          if(breakdown_edit_id){
              epic_break_data.epb_id = breakdown_edit_id;
          }
          this.$store.dispatch('epicScope/saveEpicBreakdown', epic_break_data).then((data)=>{
              this.closeEpicBreakdownDialog();
          })
          .catch((response)=> {
              this.errors.epic_break_error = response.data.error;
              //console.log(response);
          });
        },
        deleteBreakdown(epicId, epicIndex, breakdownId, breakdownIndex){
          this.$store.dispatch('epicScope/deleteEpicBreakdown', {epicId: epicId, breakdownId: breakdownId})
          .then((data)=>{
              //console.log(data);
          })
          .catch((response)=> {
              //console.log(response);
          });
        },
        saveEpicEdit(){
          var epic_data = {
            scope_id: this.currentScopeID,
            epic_id: this.epicEditData.epic_id,
            title: this.epicEditData.title,
            description: this.epicEditData.description,
            out_scope: this.epicEditData.out_scope,
            assumption: this.epicEditData.assumption,
            risks: this.epicEditData.risks,
          };
          this.$store.dispatch('epicScope/epicUpdate', epic_data).then((data)=>{
            this.closeEpicEditing();
            this.$store.commit('planning/updateEpicPricing', data.success);
          })
          .catch((response)=> {
            // console.log(response);
            this.errors.epicEditError = response.data.error;
          });
        },
        deleteEpicData(epicId) {
          this.$store.dispatch('epicScope/deleteEpic', epicId).then((data)=>{
              //console.log(data);
              this.$store.commit('planning/removeEpicBreakdown', epicId);
          })
          .catch((response)=> {
              //console.log(response);
          });
        },
        addExistingEpic(eldEpicId){
          var existing_epic = this.existing_epics.find(existing_epic => existing_epic.id == eldEpicId);

          this.epicAddData = existing_epic.epicData;
          this.saveEpic().then((epicData) => {
            existing_epic.epicData.epic_id = epicData.success.epic_id;
            existing_epic.epicData.autoAssign = true;
            this.setEpicEdit(existing_epic.epicData);
            this.saveEpicEdit();
  
            existing_epic.epicBreakdown.forEach(breakdown => {
              this.epicBreakdownAdd.epic_id = epicData.success.epic_id;
              this.epicBreakdownAdd.details = breakdown.details;
              this.epicBreakdownAdd.dependency = breakdown.dependency;
              this.epicBreakdownAdd.feature = breakdown.feature;

              if (breakdown.actor_id == 0) {
                  breakdown.actor = this.defaultActor;
              }
              var actorIndex = this.scopeActors.findIndex((
                  scopeActor => scopeActor.actor_name.trim().toLowerCase() == breakdown.actor.trim().toLowerCase()
              ));
              if(actorIndex > -1){
                  this.epicBreakdownAdd.actor_id = this.scopeActors[actorIndex].actor_id;

                  this.saveEpicBreakdown();
              } else {
                  var scopeActorData = {
                      scope_id: this.currentScopeID,
                      actor_name: breakdown.actor,
                      description: breakdown.details
                  };
                  var cloneEpicBreakdownAdd = {...this.epicBreakdownAdd};
                  this.$store.dispatch('epicScope/saveEpicActor', scopeActorData).then((actorData)=>{
                    cloneEpicBreakdownAdd.actor_id = actorData.success.actor_id;
                      this.epicBreakdownAdd = cloneEpicBreakdownAdd;

                      this.saveEpicBreakdown();
                  })
                  .catch((response)=> {
                      cloneEpicBreakdownAdd.actor_id = this.scopeActors[0].actor_id;
                      this.epicBreakdownAdd = cloneEpicBreakdownAdd;

                      this.saveEpicBreakdown();
                  });
              }
            });

            this.selectedExistingEpic = 0;
            this.existing_epic_dialog = false;
          }).catch((response)=> {
              // console.log(response);
          });

        }
    }
}
</script>

<style>
.editor-view {
  border: 1px solid #ccc;
  padding: 20px;
  min-height: 250px;
  margin-bottom: 25px;
}
.epic-item {
  margin: 20px 0;
  padding: 10px;
  background: #fff;
}
</style>