<template>
 <Layout>
  <v-card>
    <!-- table starts -->
    <div class="top_part">
      <div class="left_option">
        <div class="scope_title">Scope Manager</div>
      </div>
      <form class="right_option">
        <div class="form-group left_search_icon">
          <input type="text" class="search_part" placeholder="Search">
        </div>
        <a href="#" class="blue_btn">New Document</a>
      </form>
    </div>
    <div class="data">
      <div class="table_cover">
        <table class="table">
          <thead>
            <tr>
              <th>Document name <button class="down_arrow"><i class="fa fa-long-arrow-down"></i></button></th>
              <th>Client</th>
              <th>Created by</th>
              <th>Deadline</th>
              <th>Approvals</th>
              <th>Status<button class="ellipsis"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="pending">Approval Pending</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="draft">Draft</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="approve">Approved</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="overdue">Overdue</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="pending">Approval Pending</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="draft">Draft</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="approve">Approved</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="overdue">Overdue</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="pending">Approval Pending</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="draft">Draft</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="approve">Approved</div></td>
            </tr>
            <tr>
              <td>Laderach Scope 2021</td>
              <td>Laderach</td>
              <td>Nafees Faraz</td>
              <td>12.04.21, 23:59</td>
              <td>2/3</td>
              <td><div class="overdue">Overdue</div></td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
    <!-- table ends -->
    <!-- review -->
    <div class="top_part">
      <div class="left_option">
        <div class="scope_title">New document</div>
      </div>
      <div class="right_option">
       <div class="form-group select_icon">
        <select class="form-control">
          <option>Document 1</option>
          <option>Document 2</option>
          <option>Document 3</option>
        </select>
      </div>
    </div>
  </div>
    <div class="review">
      <h3>Finalizing & Review</h3>
      <div class="row">
        <div class="col-lg-3">
          <div class="review_list">
            <h4>Document title</h4>
            <p>Emil@Home scope statement</p>
          </div>
          <div class="review_list">
            <h4>Project client</h4>
            <p>SV Group</p>
          </div>
          <div class="review_list">
            <h4>Project deadline</h4>
            <p>16.02.2021</p>
          </div>
          <div class="review_list">
            <h4>16.02.2021</h4>
            <img src="https://shorturl.at/kpGI6" class="img-fluid cover" alt="cover">
            <p>Business Consulting</p>
          </div>
        </div>
        <div class="col-lg-3">
           <div class="review_list">
            <h4>Shared With</h4>
            <p>Nabil Adnan, Nafees Faraz, Bobby Leu, Rajiv Hasan & <a href="#">4 others</a></p>
          </div>
           <div class="review_list">
            <h4>Short description</h4>
            <p>Gravida non mauris non lacus morbi elementum lobortis. In non mattis habitant lectus. Purus quis tellus egestas dis ut. Vel, maecenas vel tortor aliquet massa. Arcu fames mattis consectetur sagittis, tempor odio elementum, fermentum amet. Nisl justo, nibh amet mattis iaculis vel. Nunc amet, volutpat tempor suspendisse. </p>
          </div>
        </div>
        <div class="offset-lg-1"></div>
        <div class="col-lg-5">
          <div class="review_list">
            <h4>Reviewer</h4>
            <p>Not added</p>
          </div>
          <div class="form-group search_icon">
            <input type="text" class="form-control " placeholder="Add document reviewer*">
            <ul class="list">
              <li>Nafees Faraz<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
              <li>Nabil Adnan<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
              <li>Bobby Leu<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
              <li>Rajiv Hasan<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
              <li>and 4 others</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- review ends-->
    <!--New document starts-->
     <div class="top_part">
        <div class="left_option">
          <div class="scope_title">New document</div>
        </div>
        <div class="right_option">
         <div class="form-group select_icon">
          <select class="form-control">
            <option>Document details</option>
            <option>Document</option>
            <option>Document 2</option>
          </select>
        </div>
      </div>
    </div>
    <div class="doc_body">
      <h3>Document Details</h3>
      <div class="row">
        <div class="col-lg-5">
          <div class="form-group">
            <input type="email" class="form-control" required="">
            <label>Emil@Home scope statement</label>
          </div>
          <div class="form-group">
            <input type="text" class="form-control" required="">
            <label>Project client*</label>
          </div>
          <div class="form-group">
            <input type="text" class="form-control" required="">
            <label>Project deadline*</label>
          </div>
          <div class="form-group select_icon">
            <select class="form-control">
              <option>Business Consulting 1</option>
              <option>Business Consulting 2</option>
              <option>Business Consulting 3</option>
              <option>Business Consulting 4</option>
              <option>Business Consulting 5</option>
            </select>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="form-group search_icon">
            <input type="text" class="form-control" required="">
            <label>Share document with</label>
          </div>
          <ul class="list">
            <li>Nafees Faraz<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
              <li>Nabil Adnan<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
              <li>Bobby Leu<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
              <li>Rajiv Hasan<button class="round_btn"><i class="fa fa-minus-circle"></i></button></li>
            <li>and 4 others</li>
          </ul>
          <div class="form-group">
            <textarea class="form-control" rows="5" required></textarea>
            <label>Short Description</label>
          </div>
        </div>
        <div class="offset-lg-2"></div>
      </div>
    </div>
    <!--New document ends-->
    <div class="top_part">
        <div class="left_option">
          <div class="scope_title">All Scope</div>
        </div>
    </div>
    <div class="fixed_button">
      <div class="button_group">
          <button class="discurd_btn">Discurd</button>
          <button class="skyblue_btn">Save as draft</button>
          <button class="blue_btn">Save & Continue</button>
      </div>
    </div>
  




    <v-card-text>
      <v-simple-table>
        <template>
          <v-data-table :headers="tableHeaders" :items="allScopes" sort-by="scope_title" item-key="scope_id" :search="search" :custom-filter="filterScopeByName">
            <template v-slot:top>
              <v-text-field v-model="search" label="Search scope" class="mx-4"></v-text-field>
            </template>
<template v-slot:item.scopeStatus="{item}">
              <td>{{ getScopeStatus(item.status) }}</td>
            </template>
<template v-slot:item.actions="{item}">
              <v-card-actions>
                <v-btn class="ma-2" small outlined color="error" @click="deleteScope(item.scope_id)">
                  <v-icon small left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn class="ma-2" small outlined color="primary" @click="duplicateScope(item.scope_id)">
                  <v-icon small left>mdi-menu</v-icon> Duplicate
                </v-btn>
                <v-btn class="ma-2" small outlined color="primary" @click="downloadScope(item.scope_id)">
                  <v-icon small left>mdi-arrow-down-bold</v-icon> Download
                </v-btn>
                <router-link class="route-btn" :to="`/scope/edit/${item.scope_id}`">
                  <v-btn class="ma-2" small outlined color="primary">
                    <v-icon small left>mdi-pencil</v-icon> Edit
                  </v-btn>
                </router-link>
              </v-card-actions>
            </template>
</v-data-table>
</template>
</v-simple-table>
</v-card-text>
</v-card>
</Layout>
</template>

<script>
    import Layout from "@/shared/Layout";
    import loader from "@/img/filter-loader.gif";

    export default {
        components: {
            Layout
        },
        data() {
            return {
                search: '',
                tableHeaders: [{
                    text: 'Scope Name',
                    align: 'start',
                    sortable: true,
                    value: 'scope_title',
                }, {
                    text: 'Scope Status',
                    align: 'start',
                    sortable: false,
                    value: 'scopeStatus',
                }, {
                    text: 'Scope Deadline',
                    align: 'start',
                    sortable: true,
                    value: 'deadline',
                }, {
                    text: 'Actions',
                    value: 'actions',
                    align: 'start',
                    sortable: false
                }, ],
                scope_available_status: [{
                    text: 'Draft',
                    value: 1
                }, {
                    text: 'Bid Open',
                    value: 2
                }, {
                    text: 'Bid Closed',
                    value: 3
                }, {
                    text: 'Won',
                    value: 4
                }, {
                    text: 'Lost',
                    value: 5
                }]
            }
        },
        mounted() {
            this.$store.dispatch('scope/fetchScopeList').then((data) => {
                    // console.log(data);
                })
                .catch((response) => {
                    // console.log(response);
                });
        },
        computed: {
            allScopes() {
                return this.$store.getters['scope/getAllScopes'];
            },
            currentScopeID() {
                return this.$store.getters['scope/getScopeID'];
            },
        },
        methods: {
            getScopeStatus(status_val) {
                var self = this;
                var selected_scope_status = self.scope_available_status.filter(function(scope_status) {
                    return scope_status.value == status_val;
                });
                return selected_scope_status[0].text;
            },
            downloadScope(scope_id) {
                this.$store.dispatch('scope/downloadScope', {
                        scope_id: scope_id
                    }).then((data) => {
                        // console.log(data);
                    })
                    .catch((response) => {
                        // console.log(response);
                    });
            },
            duplicateScope(scope_id) {
                this.$store.dispatch('scope/duplicateScope', {
                        scope_id: scope_id
                    }).then((data) => {
                        // console.log(data);
                    })
                    .catch((response) => {
                        // console.log(response);
                    });
            },
            deleteScope(scope_id) {
                if (confirm('Are you sure you want to delete the scope?')) {
                    this.$store.dispatch('scope/deleteScope', scope_id).then((data) => {
                            // console.log(data);
                        })
                        .catch((response) => {
                            // console.log(response);
                        });
                }
            },
            filterScopeByName(value, search, item) {
                return value != null && search != null &&
                    typeof value === 'string' &&
                    value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
            },
        }
    }
</script>
<style>
    .route-btn {
        text-decoration: none;
    }
</style>