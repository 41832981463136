import Vue from "vue";
import Vuex from "vuex";
import Auth from './auth.module';
import Scope from './scope.module';
import User from './user.module';
import Client from './client.module';
import Component from './component.module';
import Stakeholder from './stakeholder.module';
import DocumentOverview from './documentOverview.module';
import EpicScope from './epic.module';
import Planning from './planning.module';
import Timeline from './timeline.module';
import Appendix from './appendix.module';
import Version from './version.module';

const state = {
    layout: 'Login'
};

const getters = {
    getLayout(state){
        return state.layout;
    }
};

const mutations = {
    setLayout(state, layout) {
        state.layout = layout
    }
};

const actions = {
    currentLayout(state) {
        return state.layout;
    }
};

Vue.use(Vuex);

// create new store
const authentication =  {
    namespaced: true,
    modules: {
        auth: Auth
    }
};

const general = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

const user = {
    namespaced: true,
    modules: {
        user: User
    }
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: { 
      user,
      general,
      authentication,
      scope: Scope,
      client: Client,
      component: Component,
      stakeholder: Stakeholder,
      documentOverview: DocumentOverview,
      epicScope: EpicScope,
      planning: Planning,
      timeline: Timeline,
      appendix: Appendix,
      version: Version
  },
});

export default store;