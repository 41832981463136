<template>
    <v-card color="basil" flat>
      <v-card class="d-flex justify-space-between align-center px-4 py-4" color="lighten-2" flat >
        <v-toolbar-title>Stakeholders</v-toolbar-title>
      </v-card>
      <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Role</th>
                  <th class="text-left">Job Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="stakeholder in scope_stakeholders" :key="stakeholder.st_id">
                  <td>{{ stakeholder.stakeholder_name }}</td>
                  <td>{{ stakeholder.role_id }}</td>
                  <td>{{ stakeholder.job_title }}</td>
                  <td>
                    <v-card-actions>
                      <v-btn class="ma-2" small outlined color="error" @click="deleteStakeholder(stakeholder.st_id)">
                        <v-icon small left>mdi-delete</v-icon> remove
                      </v-btn>
                      <v-btn class="ma-2" small outlined color="primary" @click="updateStakeholderDialog(stakeholder.st_id)">
                        <v-icon small left>mdi-pencil</v-icon> Edit
                      </v-btn>
                    </v-card-actions>
                  </td>
                </tr>
              </tbody>
            </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn class="ma-2 white--text" color="blue-grey" v-on="on" @click="creating = true">
                <v-icon left>mdi-plus</v-icon> Add New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Add New Stakeholder</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="Name*" required v-model="stakeholder.stakeholder_name" :error-messages="stakeholderErrors.stakeholder_name"></v-text-field>
                    </v-col>
                  </v-row>
                    <v-row>
                  <v-col cols="12">
                      <v-text-field label="Role*" required v-model="stakeholder.role_id" :error-messages="stakeholderErrors.role_id"></v-text-field>
                    </v-col>
                  </v-row>
                    <v-row>
                  <v-col cols="12">
                      <v-text-field label="Job Title*" required v-model="stakeholder.job_title" :error-messages="stakeholderErrors.job_title"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeStakeholderDialog()">Close</v-btn>
                <v-btn color="blue darken-1" text @click="addStakeholder()" v-if="creating">Save</v-btn>
                <v-btn color="blue darken-1" text @click="updateStakeholder(currentStakeholder)" v-if="!creating">Update</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      creating: false,
      stakeholder: {
        stakeholder_name: '',
        role_id: '',
        job_title: ''
      },
      currentStakeholder: 0
    }
  },
  mounted() {
		this.fetchScopeStakeholders();
  },
  computed: {
    ...mapState('stakeholder', ['scope_stakeholders']),
    currentScopeID(){
        return this.$store.getters['scope/getScopeID'];
    },
    stakeholderErrors(){
        var errors = this.$store.getters['stakeholder/getErrors'];
        var error_data = {};

        if (typeof errors.errors == 'undefined') {
            return error_data;
        }

        error_data['stakeholder_name'] = typeof errors.errors.stakeholder_name != 'undefined' ? errors.errors.stakeholder_name[0] : '';
        error_data['role_id'] = typeof errors.errors.role_id != 'undefined' ? errors.errors.role_id[0] : '';
        error_data['job_title'] = typeof errors.errors.job_title != 'undefined' ? errors.errors.job_title[0] : '';

        return error_data;
    }
  },
  methods: {
    defaultStakeholder() {
      return {
          st_id: 0,
          stakeholder_name: '',
          role_id: '',
          job_title: ''
      };
    },
    fetchScopeStakeholders(){
        var scope_id = this.currentScopeID;

        this.$store.dispatch('stakeholder/fetchScopeStakeholders', scope_id).then((data)=>{
            // console.log(data.success);
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    addStakeholder(){
        var new_stakeholder = this.stakeholder;
        var stakeholder_data = {
            stakeholder_name: new_stakeholder.stakeholder_name,
            role_id: new_stakeholder.role_id,
            job_title: new_stakeholder.job_title,
            scope_id: this.currentScopeID
        }
        this.$store.dispatch('stakeholder/addNewStakeholder', stakeholder_data).then((data)=>{
            this.stakeholder = this.defaultStakeholder();
            this.dialog = false;
            this.creating = false;
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    updateStakeholderDialog(stakeholder_id){
        var stakeholder_index = this.scope_stakeholders.findIndex((stakeholder => stakeholder.st_id == stakeholder_id));

        this.dialog = true;
        this.stakeholder = {
          st_id: this.scope_stakeholders[stakeholder_index].st_id,
          stakeholder_name: this.scope_stakeholders[stakeholder_index].stakeholder_name,
          role_id: this.scope_stakeholders[stakeholder_index].role_id,
          job_title: this.scope_stakeholders[stakeholder_index].job_title,
          scope_id: this.currentScopeID
        };
        this.currentStakeholder = stakeholder_id;
    },
    closeStakeholderDialog(stakeholder_id){
        this.dialog = false;
        this.creating = false;
        this.stakeholder = this.defaultStakeholder();
        this.$store.commit('stakeholder/clearErrors');
    },
    updateStakeholder(stakeholder_id){
        var old_stakeholder = this.stakeholder;
        var stakeholder_data = {
            st_id: stakeholder_id,
            stakeholder_name: old_stakeholder.stakeholder_name,
            role_id: old_stakeholder.role_id,
            job_title: old_stakeholder.job_title,
            scope_id: this.currentScopeID
        }
        this.$store.dispatch('stakeholder/updateScopeStakeholder', stakeholder_data).then((data)=>{
            this.stakeholder = this.defaultStakeholder();
            this.dialog = false;     
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    deleteStakeholder(stakeholder_id){
        this.$store.dispatch('stakeholder/deleteScopeStakeholder', stakeholder_id).then((data)=>{
            // console.log(response);
        })
        .catch((response)=> {
            // console.log(response);
        });
    }
  }
}
</script>

<style>

</style>