import ApiService from '../api/api.service';
import Errors from '../form';

const getDefaultClientData = () => {
    return {
        name: '',
        user_id: 0,
        address: '',
        client_id: 0,
        company_name: '',
    };
};

const state = {
    allClients: [],
    fullClientList: [],
    errors: new Errors(),
    clientData: getDefaultClientData()
};

const getters = {
    getClient(state){
        return state.clientData;
    },
    getClientList(state){
        return state.allClients;
    },
    getErrors(state){
        return state.errors;
    },
    getFullClientList(state){
        return state.fullClientList;
    }
};

const mutations = {
    setClientList(state, data) {
        var clientList = [];
        for (var client_id in data.client) {
            var client_item = {};
            client_item['value'] = client_id;
            client_item['text'] = data.client[client_id];
            clientList.push(client_item);
        }
        state.allClients = clientList;
    },
    setError(state, {target, message}) {
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setFullClientList(state, data){
        state.fullClientList = data;
    },
    setClient(state, data) {
        state.clientData = data;
    },
    resetClient(state) {
        state.clientData = getDefaultClientData();
    },
    removeClient(state, clientId) {
        state.fullClientList = state.fullClientList.filter(client => client.client_id !== clientId);
    }
}

const actions = {
    fetchClient(context, clientId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/client', `?client_id=${clientId}`).then(({data}) => {
                context.commit('setClient', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    fetchClientList(context) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/client-list').then(({data})=>{
                context.commit('setClientList', {client: data.success });
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    addNewClient(context, client_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/create-client', {...client_arguments}).then(({data}) => {
                context.commit('clearErrors');
                context.commit('setClient', data.success);
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'create_client', message: response.data.error});
                reject(response);
            });
        });
    },
    updateClient(context, client_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/client-edit', {...client_arguments}).then(({data}) => {
                context.commit('clearErrors');
                context.commit('setClient', data.success);
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'create_client', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchFullClientList(context){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/client-list-details').then(({data})=>{
                context.commit('setFullClientList', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    deleteComponent(context, clientId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`api/client-delete?client_id=${clientId}&force=false`).then(({data}) => {
                context.commit('clearErrors');
                context.commit('removeClient', clientId);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    resetClientData(context) {
        context.commit('clearErrors');
        context.commit('resetClient');
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};