import ApiService from '../api/api.service';
import JwtService from "../common/jwt.service";
import Errors from '../form';
import router from '../router';

const getDefaultState = () => {
    return {
        user: {
            userId: 0,
            name: "",
            email: ""
        },
        errors: new Errors(),
        isAuthenticated: !!JwtService.authCheck()
    }
};

const state = getDefaultState();

const getters = {
    getErrors(state){
        return state.errors;
    },

    currentUser(state){
        return state.user;
    },

    currentUserId(state){
        return state.user.userId;
    },
    
    isAuthenticated(state){
        return state.isAuthenticated;
    }
};

const mutations = {
    clearErrors(state) {
        state.errors = [];
    },
    setError(state, {target, message}) {
        // console.log(message);
        state.errors.clear();
        state.errors.record(message);
    },
    setUser(state, data) {
        state.user.userId = data.token.user_id;
        state.user.name = data.token.name;
        state.user.email = data.token.email;
        state.isAuthenticated = true;
        state.errors = getDefaultState().errors;
        JwtService.setToken(data.token.access_token);
        JwtService.setRefreshToken(data.token.refresh_token);
    },
    logout(state) {
        JwtService.unsetToken();
        JwtService.unsetRefreshToken();
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    login(context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post('api/login', {...credentials})
            .then(({data})=>{
                // console.log(data);
                context.commit('clearErrors');
                context.commit('setUser', {token: data.success});
                resolve(data);
            })
            .catch(({response}) => {
                //console.log(response);
                context.commit('setError', {target: 'login', message: response.data.error});
                reject(response);
            });
        });
    },
    logout(context) {
        context.commit('logout');
        router.push('/');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};