import ApiService from '../api/api.service';
import Errors from '../form';

const state = {
    scope_list: [],
    scope_id: 0,
    errors: new Errors()
};

const getters = {
    getErrors(state){
        return state.errors;
    },
    getScopeID(state){
        return state.scope_id;
    },
    getAllScopes(state){
        return state.scope_list;
    }
};

const mutations = {
    setScopeID(state, data) {
        state.scope_id = data.scope_id;
    },
    setError(state, {target, message}) {
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setScopeList(state, data){
        state.scope_list = data.scope;
    },
    addScopeList(state, data){
        var scope_list = state.scope_list;
            scope_list.push(data.scope);

        state.scope_list = scope_list;
    },
    deleteScopeFromList(state, scopeID){
        state.scope_list = state.scope_list.filter(scope => scope.scope_id !== scopeID);
    }
}

const actions = {
    addNewScope(context, scope_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/create-scope', {...scope_arguments}).then(({data})=>{
                context.commit('clearErrors');
                context.commit('setScopeID', {scope_id: data.success.scope_id });
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'create_scope', message: response.data.error});
                reject(response);
            });
        });
    },
    editScope(context, scope_arguments){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/edit-scope', {...scope_arguments})
            .then(({data})=>{
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'create_scope', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchScopeList(context){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/scope-list').then(({data})=>{
                context.commit('setScopeList', {scope: data.success});
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    fetchScopeData(context, scope_id){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/scope-data', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setScopeID', {scope_id: data.success.scope_id });
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    deleteScope(context, scope_id){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`api/delete-scope?scope_id=${scope_id}`).then(({data})=>{
                context.commit('deleteScopeFromList', scope_id);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    downloadScope(context, scope_arguments){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/scope-doc-download', {...scope_arguments}).then(({data})=>{
                var url = data.success.file;
                var a = document.createElement("a");
                a.href = url;
                a.setAttribute('download', url.split("/").pop());
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    duplicateScope(context, scope_arguments){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/duplicate-scope', {...scope_arguments}).then(({data})=>{
                context.commit('addScopeList', {scope: data.success});
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    fileContents(binaryString) {
        var len = binaryString.length;
        var arr = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            arr[i] = binaryString.charCodeAt(i);
        }
        var data = new Blob([arr], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });
        var dataURL = window.URL.createObjectURL(data);
        var fileLink = document.createElement('a');
        fileLink.href = dataURL;
        fileLink.setAttribute('download', 'file.docx');
        document.body.appendChild(fileLink);

        fileLink.click();
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};