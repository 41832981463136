import ApiService from '../api/api.service';
import Errors from '../form';

const getDefaultBackgroundObjective = () => {
    return {
        doid: 0,
        about: `<p>The purpose of this document is to define the scope of the client&rsquo;s project. 
        This document will serve as a Development Scope Statement under the Terms of the General Software Services
         Agreement between Laderach AG and SELISE. If no specific contract between the customer exists, 
         the standard terms apply as they can be found under 
         <a href="https://www.selise.ch/terms">www.selise.ch/terms</a>.</p> <p>Key Details</p> 
         <ul> <li> <p>Document Prepared by - Name, Title, Company</p> </li> <li> <p>Business Unit/Agency</p> 
         </li> <li> <p>----------------------------</p> </li> <li> <p>Document Version - PCD-RXX-YYYYMMDD</p> 
         </li> <li> <p>Issue Date</p> </li> <li> <p>Valid till</p> </li> 
         <li> <p>----------------------------</p> </li> <li> <p>Development Approval - Name, Title, Company</p> </li>
          <li> <p>Sales Approval - Name, Title, Company</p> </li> <li> <p>Primary Contact &nbsp; </p> </li>
           <ul> <li> <p>Name, Title, Company</p> </li> <li> <p>Email</p> </li> <li> <p>Phone</p> </li> </ul> </ul>`,
        summary: `<p>This section summarized all the sections discussed in this document. 
        The core idea of this section is to give a quick overview of top-management about this project.&nbsp;</p>
         <p>Key Details</p> <ul> <li> <p>Account/Organization Name</p> </li> <li> <p>Project Name</p> </li> <li>
          <p>----------------------------</p> </li> <li> <p>Release No.</p> </li> <li> 
          <p>Development Timeline &nbsp; </p> </li> <ul> <li> <p>Start &amp; End Date</p> </li> <li> <p>Months</p> 
          </li> </ul> <li> <p>Pricing</p> </li> <li> <p>----------------------------</p> </li> <li> <p>Release No.</p>
           </li> <li> <p>Development Timeline &nbsp; </p> </li> <ul> <li> <p>Start &amp; End Date</p> </li> <li> 
           <p>Months</p> </li> </ul> <li> <p>Pricing</p> </li> <li> <p>----------------------------</p> </li> <li> 
           <p>Decision Makers -&nbsp; &nbsp; &nbsp; </p> </li> <ul> <li> <p>Name, Title, Company</p> </li> <li> 
           <p>Name, Title, Company</p> </li> </ul> <li> <p>Primary Contact &nbsp; </p> </li> <ul> <li> 
           <p>Name, Title, Company</p> </li> <li> <p>Email</p> </li> <li> <p>Phone</p> </li> </ul> </ul> `,
        objective: `<p>The core objection of this project is to provide a digital touch point between the client ans its 
        corporate partners.&nbsp; It aims to reach out to existing and potential customers in a new way with more 
        opportunity for engagement and conversion.</p> <p>The core functionality of this site will be to offer:</p> 
        <ul> <li> <p>Touchpoint between the client and its corporate partners</p> </li> <li> 
        <p>Intuitive Product customizer</p> </li> <li> <p>Contact info for Regional offices</p> </li> 
        <li> <p>Product catalog</p> </li> </ul> `
    }
};

const state = {
    errors: new Errors(),
    scope_doc:  getDefaultBackgroundObjective()
};

const getters = {
    getErrors(state){
        return state.errors;
    },
    getScopeDocOverview(state){
        return state.scope_doc;
    }
};

const mutations = {
    setError(state, {target, message}) {
        //console.log(message);
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setScopeDocOverview(state, doc_data){
        if(doc_data){
            state.scope_doc = doc_data;
        } else {
            state.scope_doc = getDefaultBackgroundObjective();
        }
    },
    setScopeBackgroundField(state, field){
        state.scope_doc[field.key] = field.val;
    },
}

const actions = {
    addNewDocumentOverview(context, document_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/create-document-overview', {...document_arguments})
            .then(({data})=>{
                //console.log(data);
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'add_document_overview', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchScopeOverview(context, scope_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/document-overview', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setScopeDocOverview', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};