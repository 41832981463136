<template>
  <v-app id="sandbox">
    <v-navigation-drawer
      app
      dark 
      class="blue-grey darken-4" 
      v-model="primaryDrawer.model" 
      :mini-variant="primaryDrawer.mini"
      overflow
    >
    
      <v-img :contain="true" :max-height="64" :src="logo"></v-img>

      <v-divider></v-divider>
      <v-divider></v-divider>

      <v-list dense>

        <v-list-group v-for="item in items" :key="item.title" 
          v-model="item.active" :prepend-icon="item.action"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title">
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="subItem in item.items"
            :key="subItem.title" :to="{ name: subItem.route}">
          <v-list-item-content>
            <v-list-item-title>
                  {{subItem.title}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
        
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="primaryDrawer.clipped"
      app
    >
      <v-app-bar-nav-icon
        v-if="primaryDrawer.type !== 'permanent'"
        @click.stop="primaryDrawer.mini = !primaryDrawer.mini"
      />
      <v-toolbar-title>Scope Manager</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu bottom origin="center bottom" :offset-x="true" :offset-y="false" transition="scale-transition">
          <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
                <v-avatar class="white" size="32">
                    <v-icon color="primary">person</v-icon>
                </v-avatar>
            </v-btn>
          </template>
            <v-list class="pa-0" light>
            <v-list-item>
                <v-list-item-avatar>
                    <v-avatar class="primary" size="48px">
                        <v-icon dark>person</v-icon>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>John Doe</v-list-item-title>
                    <v-list-item-subtitle>Administrator</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item key="profile">
                <v-list-item-action>
                    <v-icon>mdi-account</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>My Profile</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item key="lock_open" @click.prevent="logout()">
                <v-list-item-action>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <v-fade-transition mode="out-in">
            <slot></slot>
            </v-fade-transition>
          </v-col>
        </v-row>
        <v-row>
        </v-row>
      </v-container>
    </v-main>

    <v-footer
      :inset="footer.inset"
      app
    >
      <span class="px-4">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
  import logo from "@/img/selise-logo.png";

  export default {
    data: () => ({
      drawers: ['Default (no property)', 'Permanent', 'Temporary'],
      primaryDrawer: {
        model: null,
        type: 'default (no property)',
        clipped: false,
        floating: false,
        mini: false,
      },
      logo: logo,
      footer: {
        inset: false,
      },
      items: [
          {
            action: 'mdi-border-all-variant',
            title: 'Scope',
            items: [
              { title: 'View All', route: 'allscope' },
              { title: 'Add New', route: 'newscope' },
            ],
          },
          {
            action: 'mdi-account-multiple',
            title: 'Users',
            items: [
              { title: 'All User', route: 'listuser' },
              { title: 'Add User', route: 'newuser' },
            ],
          },
          {
            action: 'mdi-face-agent',
            title: 'Customers',
            items: [
              { title: 'All Clients', route: 'clientlist' },
              { title: 'Add Client', route: 'newclient' },
            ],
          },
          {
            action: 'mdi-tools',
            title: 'Reusable',
            items: [
              { title: 'All Components', route: 'allcomponent' },
              { title: 'Add New', route: 'addcomponent' },
            ],
          },
        ],
    }),
    methods: {
      showMini() {
        this.primaryDrawer.model = true;
        this.primaryDrawer.mini = !this.primaryDrawer.mini;
      },
      logout() {
          this.$store.dispatch('authentication/logout');
      }
    }
  }
</script>