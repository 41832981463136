<template>
 <Layout>
  <v-card>
    <v-card-title>All Users</v-card-title>
    <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in allUsers" :key="user.id">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <router-link class="route-btn" :to="`/user/edit/${user.id}`" v-show="true">
                    <v-btn class="ma-2" small outlined color="primary">
                        <v-icon small left>mdi-pencil</v-icon> Edit
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
      </v-simple-table>
    </v-card-text>
  </v-card>

 </Layout>
</template>

<script>
import Layout from "@/shared/Layout";
import loader from "@/img/filter-loader.gif";

export default {
    components: {
        Layout
    },
    data() {
        return {
            
        }
    },
    mounted() {
        this.$store.dispatch('user/fetchFullUserList').then((data)=>{
            //console.log(data);
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    computed: {
      allUsers(){
        return this.$store.getters['user/getFullUserList'];
      }
    },
}
</script>