<template>
  <v-card color="basil" flat>
    <v-card class="d-flex justify-space-between align-center px-4 py-4" color="lighten-2" flat >
      <v-toolbar-title>Epic, features &amp; Backlogs</v-toolbar-title>
    </v-card>
    <v-card-text>
      <v-container>
         <v-row class="pb-7">
          <v-col cols="12 pb-7">
              <v-toolbar-title class="pb-5">Background</v-toolbar-title>
              <ckeditor v-model="featureBackground" class="mb-4" :config="editorConfig"></ckeditor>
              <div class="text-left">
                <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveScopeEpicBackground">Save</v-btn>
              </div>
              <div class="success-message" v-if="saveSuccess">
                <p>Data Updated Successfully</p>
              </div>
          </v-col>
          <v-col cols="12">
              <v-toolbar-title class="pb-5">Actors</v-toolbar-title>
              <v-card-text class="px-0 py-0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Actors</th>
                        <th class="text-left">Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="actor in epic_data.epic_table" :key="actor.actor_id">
                        <td>{{ actor.actor_name }}</td>
                        <td>{{ actor.description }}</td>
                        <td>
                          <v-card-actions>
                            <v-btn class="ma-2" small outlined color="error" :disabled="!canDeleteActor(actor.actor_id)" @click="actorDelete(actor.actor_id)">
                              <v-icon small left>mdi-delete</v-icon> Remove
                            </v-btn>
                            <v-btn class="ma-2" small outlined color="primary" @click="actorEditDialog(actor.actor_id)">
                              <v-icon small left>mdi-pencil</v-icon> Edit
                            </v-btn>
                          </v-card-actions>
                        </td>
                      </tr>
                    </tbody>
                  </template>
              </v-simple-table>
            </v-card-text>
            <v-dialog v-model="actor_dialog" persistent max-width="600px">
              <template v-slot:activator="{ on }">
                <div class="text-right">
                  <v-btn class="ma-2 white--text mt-4" color="blue-grey" v-on="on">
                    <v-icon left>mdi-plus</v-icon> Add Actor
                  </v-btn>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline" v-if="!actor_data.actor_id">Add new actor</span>
                  <span class="headline" v-else>Edit actor</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="Name*" v-model="actor_data.name" :error-messages="actorCreationErrors.actor_name" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="Description*" v-model="actor_data.description" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeActorDialog()">Close</v-btn>
                  <v-btn color="blue darken-1" text @click="saveActor()">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <scopeEpicList></scopeEpicList>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import scopeEpicList from "./scopeEpicList";
import { CKEDITOR_CONFIG } from '../../../common/config';

export default {
  components: {
    scopeEpicList
  },
  data() {
      return {
          actor_dialog: false,
          saveSuccess: false,
          actor_data: this.defaultActorData(),
          errors: {
            actor_error: ''
          },
          editorConfig: CKEDITOR_CONFIG,
      }
  },
  mounted(){
    this.saveScopeEpicBackground(true);
    this.$store.dispatch('epicScope/fetchEpicScopeData', this.currentScopeID).then((data)=>{
      // console.log(data);
    })
    .catch((response)=> {
      // console.log(response);
    });
    this.$store.dispatch('epicScope/fetchExistingEpicScopeData').then((data)=>{
      // console.log(data.success);
    })
    .catch((response)=> {
      // console.log(response);
    });
    var pricing_data = this.$store.getters['planning/getPricingData'];
    if(pricing_data.scope_id != this.currentScopeID ){
      this.$store.dispatch('planning/fetchEpicPricingBreakdown', this.currentScopeID).then((data)=>{
        // console.log(data);
      })
      .catch((response)=> {
        // console.log(response);
      });
    }
  },
  computed: {
    ...mapState('epicScope', ['epic_data']),
    featureBackground: {
      get(){
        return this.epic_data.background;
      },
      set(feature_background){
        this.$store.commit('epicScope/setFeatureBackground', feature_background);
      }
    },
    currentScopeID(){
        return this.$store.getters['scope/getScopeID'];
    },
    actorCreationErrors(){
      var error_data = {};

      if (typeof this.errors.actor_error == 'undefined') {
        return error_data;
      }
      
      error_data['actor_name'] = typeof this.errors.actor_error.actor_name != 'undefined' ? this.errors.actor_error.actor_name[0] : '';
      return error_data;
    }
  },
  methods:{
    saveScopeEpicBackground(forceSave){
      var epicBackground = {
        scope_id: this.currentScopeID,
        background: this.featureBackground
      };
      if (typeof forceSave == 'boolean') {
        epicBackground.force_save = forceSave;
      }
      this.$store.dispatch('epicScope/saveEpicBackground', epicBackground).then((data)=>{
        this.saveSuccess = true;

        setTimeout(() => {
          this.saveSuccess = false;
        }, 3000);
      })
      .catch((response)=> {
        // console.log(response);
      });
    },
    actorEditDialog(actor_id){
      var self = this;
      var actor_edit = self.epic_data.epic_table.filter(function (actor) {
        return actor.actor_id == actor_id;
      });
      this.actor_data.name = actor_edit[0].actor_name;
      this.actor_data.description = actor_edit[0].description;
      this.actor_data.actor_id = actor_id;
      this.actor_dialog = true;
    },
    closeActorDialog(){
      this.actor_dialog = false;
      this.actor_data = this.defaultActorData();
      this.errors.actor_error = '';
    },
    saveActor(){
      this.errors.actor_error = '';
      var actor_save_data = {
        scope_id: this.currentScopeID,
        actor_name: this.actor_data.name,
        description: this.actor_data.description
      };
      var actor_edit_id = this.actor_data.actor_id;
      if(actor_edit_id){
        actor_save_data.actor_id = actor_edit_id;
      }
      this.$store.dispatch('epicScope/saveEpicActor', actor_save_data).then((data)=>{
          this.closeActorDialog();
      })
      .catch((response)=> {
          this.errors.actor_error = response.data.error;
      });
    },
    canDeleteActor(actor_id) {
      var actorFound = true;

      if (this.epic_data.get_epic.length) {
        for (let epicActor of Object.values(this.epic_data.get_epic)) {
          if (epicActor.epic_module.find(actor => actor.actor_id == actor_id)) {
            actorFound = false;
            break;
          }
        }
      }

      return actorFound;
    },
    actorDelete(actor_id) {
      this.$store.dispatch('epicScope/deleteEpicActor', actor_id).then((data)=>{
        // console.log(data);
      })
      .catch((response)=> {
        // console.log(response);
      });
    },
    defaultActorData(){
      return {
        name: '',
        description: '',
        actor_id: 0
      }
    }
  }
}
</script>