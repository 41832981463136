<template>
    <div class="product-details">
		<v-container>
		<v-row>
			<v-col cols="12">
				<v-text-field label="Title*" v-model="productDetails.title" :error-messages="productDertailsCreationErrors.title_error"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<h3 class="editor-label">Description</h3>
				<ckeditor v-model="productDetails.description" :config="editorConfig" required></ckeditor>
				<div class="v-text-field__details" v-if="productDertailsCreationErrors.description_error">
					<div class="v-messages theme--light error--text" role="alert">
						<div class="v-messages__wrapper">
							<div class="v-messages__message">The description field is required.</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		</v-container>
    </div>
</template>

<script>

import { CKEDITOR_CONFIG } from '../../../common/config';

export default {
    data() {
		return {
			productDetails: this.defaultProductDetails(),
			productDertailsCreationErrors: {
				title_error: '',
				description_error: ''
			},
			editorConfig: CKEDITOR_CONFIG,
		}  
    },
    methods: {
		setProductDetailsErrors(error_data){
			this.productDertailsCreationErrors = error_data;
        },
        defaultProductDetails(){
			return {
				title: '',
				description: ''
			};
        },
        storeProductDetails(){
			var productDetails = {
				title: this.productDetails.title,
				json_dump: this.productDetails.description
			};

			this.$store.commit('component/setProductDetails', productDetails);
		},
		setProductDetails(data) {
			this.productDetails.title = data.title;
			this.productDetails.description = data.json_dump;
		}
    }
}
</script>