<template>
 <Layout>
  <v-card>
    <v-alert type="warning" v-if="canNotDelete">
        The client you are trying to delete, you can't delete.
    </v-alert>
    <v-card-title>All Clients</v-card-title>
    <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Company Name</th>
                <th class="text-left">Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="client in allClients" :key="client.client_id">
                <td>{{ client.name }}</td>
                <td>{{ client.company_name }}</td>
                <td>{{ client.address }}</td>
                <td>
                  <v-btn class="ma-2" small outlined color="error" @click="deleteClient(client.client_id)">
                    <v-icon small left>mdi-delete</v-icon> Delete
                  </v-btn>
                  <router-link class="route-btn" :to="`/client/edit/${client.client_id}`">
                    <v-btn class="ma-2" small outlined color="primary">
                        <v-icon small left>mdi-pencil</v-icon> Edit
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
      </v-simple-table>
    </v-card-text>
  </v-card>

 </Layout>
</template>

<script>
import Layout from "@/shared/Layout";
import loader from "@/img/filter-loader.gif";

export default {
    components: {
        Layout
    },
    data() {
        return {
            canNotDelete: false
        }
    },
    mounted() {
        this.$store.dispatch('client/fetchFullClientList').then((data)=>{
            //console.log(data);
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    computed: {
      allClients(){
        return this.$store.getters['client/getFullClientList'];
      }
    },
    methods: {
      deleteClient(clientId) {
        this.$store.dispatch('client/deleteComponent', clientId).then((data)=>{
            // console.log(data);
        })
        .catch((response)=> {
			    this.canNotDelete = true;
			
			    setTimeout(() => {
            this.canNotDelete = false;
          }, 3000);
        });
      }
    },
}
</script>