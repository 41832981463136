<template>
 <Layout>
  <v-card>
    <v-alert type="warning" v-if="canNotDelete">
        The Component you trying to delete, you can't delete it.
    </v-alert>
    <v-card-title>All Components</v-card-title>
    <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Component Name</th>
                <th class="text-left">Component Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="component in allComponents" :key="component.componentId">
                <td>{{ component.title }}</td>
                <td>{{ component.type }}</td>
                <td>
                  <v-btn class="ma-2" small outlined color="error" @click="deleteComponent(component.component_id)">
                    <v-icon small left>mdi-delete</v-icon> Delete
                  </v-btn>
                  <router-link class="route-btn" :to="`/component/edit/${component.component_id}`">
                    <v-btn class="ma-2" small outlined color="primary">
                        <v-icon small left>mdi-pencil</v-icon> Edit
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
      </v-simple-table>
    </v-card-text>
  </v-card>

 </Layout>
</template>

<script>
import Layout from "@/shared/Layout";
import loader from "@/img/filter-loader.gif";

export default {
    components: {
        Layout
    },
    data() {
        return {
            canNotDelete: false
        }
    },
    mounted() {
        this.$store.dispatch('component/fetchComponentList').then((data)=>{
            // console.log(data);
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    computed: {
      allComponents(){
          return this.$store.getters['component/getFullComponentList'];
      }
    },
    methods: {
      deleteComponent(commentId) {
        this.$store.dispatch('component/deleteComponent', commentId).then((data)=>{
            // console.log(data);
        })
        .catch((response)=> {
			    this.canNotDelete = true;
			
			    setTimeout(() => {
            this.canNotDelete = false;
          }, 3000);
        });
      }
    },
}
</script>