<template>
    <div class="epic-area">
      	<v-container>
			<v-row>
				<v-col cols="12">
					<v-text-field label="Title*" v-model="epicData.title" :error-messages="epicCreationErrors.title_error"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<h3 class="editor-label">Description</h3>
					<ckeditor v-model="epicData.description" :config="editorConfig" required></ckeditor>
					<br/>
					<div class="v-text-field__details" v-if="(epicCreationErrors.description_error != '')">
						<div class="v-messages theme--light error--text" role="alert">
							<div class="v-messages__wrapper">
								<div class="v-messages__message">{{ epicCreationErrors.description_error }}</div>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<h3 class="editor-label">Out Scope</h3>
					<ckeditor v-model="epicData.out_scope" :config="editorConfig"></ckeditor>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<h3 class="editor-label">Assumption</h3>
					<ckeditor v-model="epicData.assumption" :config="editorConfig"></ckeditor>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<h3 class="editor-label">Risks</h3>
					<ckeditor v-model="epicData.risks" :config="editorConfig"></ckeditor>
				</v-col>
			</v-row>
      	</v-container>

		<v-container>
			<v-row>
				<v-col cols="12">
					<h3 class="editor-label">Story</h3>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Features</th>
									<th class="text-left">Actor</th>
									<th class="text-left">Details</th>
									<th class="text-left">Dependency</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(breakdown, breakdown_index) in epicBreakdowns" :key="breakdown_index">
									<td>{{ breakdown.feature }}</td>
									<td>{{ getActorName(breakdown) }}</td>
									<td>{{ breakdown.details }}</td>
									<td>{{ breakdown.dependency }}</td>
									<td>
										<v-btn class="ma-2" small outlined color="error" @click="deleteEpicBreakdown(breakdown_index)">
											<v-icon small left>mdi-delete</v-icon> Delete
										</v-btn>
										<v-btn class="ma-2" small outlined color="primary" @click="editEpicBreakdown(breakdown_index)">
											<v-icon small left>mdi-pencil</v-icon> edit
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<div class="text-right">
						<v-btn class="ma-2 white--text mt-4" color="blue-grey" @click="addEpicBreakdown()">
							<v-icon left>mdi-plus</v-icon> Add more
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog v-model="epicBreakdownPopup" persistent max-width="600px">
			<v-card>
				<v-container>
					<v-row>
						<v-col cols="12">
							<span class="headline"> {{ epicBreakdown.breakdown_edit_id ? 'Edit breakdown' : 'Add new breakdown' }}</span>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field label="Feature*" v-model="epicBreakdown.feature" :error-messages="errors.epicFeature"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field label="actor*" v-model="epicBreakdown.actor" :error-messages="errors.epicActor"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field label="Details" v-model="epicBreakdown.details"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field label="Dependency*" v-model="epicBreakdown.dependency" :error-messages="errors.epicDependency"></v-text-field>
						</v-col>
					</v-row>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="closeEpicBreakdown()" :disabled="!epicBreakdown.feature || !epicBreakdown.actor || !epicBreakdown.dependency">Close</v-btn>
						<v-btn color="blue darken-1" text @click="saveEpicBreakdown()">Save</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>

import { CKEDITOR_CONFIG, DEFAULT_ACTOR } from '../../../common/config';

export default {
    data() {
		return {
			epicBreakdownPopup: false,
			epicBreakdownEditing: false,
			epicData: this.defaultEpicData(),
			epicBreakdown: this.defaultEpicBreakdown(),
			epicBreakdowns: [],
			epicCreationErrors: this.defaultErrorData(),
			editorConfig: CKEDITOR_CONFIG,
			defaultActor: DEFAULT_ACTOR,
			errors: {
				epicFeature: '',
				epicActor: '',
				epicDependency: ''
			}
		}  
    },
    methods: {
		defaultEpicData(){
			return {
				title: '',
				description: '',
				out_scope: '',
				assumption: '',
				risks: ''
			};
		},
		defaultEpicBreakdown(){
			return {
				actor: '',
				actor_id: 0,
				feature: '',
				details: '',
				dependency: ''
			};
		},
		defaultErrorData(){
			return {
				title_error: '',
				description_error: ''
			};
		},
		setScopeEpicErrors(error_data) {
			this.epicCreationErrors = error_data;
		},
		getActorName(breakdown){
			if (breakdown.actor_id == 0) {
				return this.defaultActor;
			} else {
				return (typeof breakdown.actor == 'undefined') ? '' : breakdown.actor.trim();
			}
		},
		storeScopeEpic() {
			var newScopeEpic = {
				title: this.epicData.title,
				json_dump: JSON.stringify({
					epicData: this.epicData,
					epicBreakdown: this.epicBreakdowns,
				})
			};
			this.setScopeEpicErrors(this.defaultErrorData());
			this.$store.commit('component/setScopeEpic', newScopeEpic);
		},
		addEpicBreakdown() {
			this.epicBreakdownPopup = true;
		},
		closeEpicBreakdown() {
			this.epicBreakdownPopup = false;
			this.epicBreakdownEditing = false;
			this.epicBreakdown = this.defaultEpicBreakdown();
		},
		editEpicBreakdown(index) {
			this.epicBreakdownEditing = true;
			this.epicBreakdown = this.epicBreakdowns[index];
			this.epicBreakdown.actor = this.getActorName(this.epicBreakdown);
			this.addEpicBreakdown();
		},
		deleteEpicBreakdown(index) {
			this.epicBreakdowns.splice(index, 1);
		},
		saveEpicBreakdown() {
			this.errors.epicActor = '';
			this.errors.epicFeature = '';
			this.errors.epicDependency = '';

			if (!this.epicBreakdown.actor || !this.epicBreakdown.feature || !this.epicBreakdown.dependency) {
				if (!this.epicBreakdown.actor) {
					this.errors.epicActor = 'The actor field is required.';
				}
				if (!this.epicBreakdown.feature) {
					this.errors.epicFeature = 'The feature field is required.';
				}
				if (!this.epicBreakdown.dependency) {
					this.errors.epicDependency = 'The dependency field is required.';
				}
				
				return;
			}

			if (this.epicBreakdown.actor && this.epicBreakdown.actor != this.defaultActor) {
				this.epicBreakdown.actor_id = -1;
			}
			if (!this.epicBreakdownEditing) {
				this.epicBreakdowns.push(this.epicBreakdown);
			}

			this.closeEpicBreakdown();
		},
		setScopeEpic(data) {
			if (typeof data.json_dump != 'undefined') {
				var scopeEpicData = JSON.parse(data.json_dump);

				this.epicData = scopeEpicData.epicData;
				this.epicBreakdowns = scopeEpicData.epicBreakdown;
			}
		}
    }
}
</script>

<style>
.editor-view {
  border: 1px solid #ccc;
  padding: 20px;
  min-height: 250px;
  margin-bottom: 25px;
}
.epic-item {
  margin: 20px 0;
  padding: 10px;
  background: #fff;
}
</style>