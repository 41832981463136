<template>
 <Layout>
  
  <v-card>
      <v-alert type="warning" v-if="scopeNotFound">
        The scope you trying to edit can't be found. Please add a new scope.
      </v-alert>
      <v-card-title>{{ scope_heading }}</v-card-title>
      <v-card-text >
        <v-row class="pb-7">
            <v-col cols="6" lg="6" sm="12" class="pr-7">
                <v-text-field v-model="scope.scope_title" label="Scope Title" :error-messages="scopeCreationErrors.title_error"></v-text-field>
                <v-select v-model="scope.scope_client" :items="allClientList" label="Add Clients" :error-messages="scopeCreationErrors.client_error"></v-select>
                <v-select v-model="scope.scope_status" :items="scope_available_status" label="Status"></v-select>
                <div class="scope-deadline">
                    <v-menu
                        v-model="scope.scope_deadline.showDatepicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="scope.scope_deadline.date" label="Deadline" prepend-icon="" readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="scope.scope_deadline.date" @input="scope.scope_deadline.showDatepicker = false"></v-date-picker>
                    </v-menu>
                </div>
                <v-select v-model="scope.scope_users" :items="allUserList" label="Add Users" multiple :error-messages="scopeCreationErrors.user_error"></v-select>
                <v-select v-model="scope.cover_photo" :items="coverPhotos" label="Select Cover Photo" :error-messages="scopeCreationErrors.cover_photo">
                    <template v-slot:item="{ item }">
                        <img :src="imageBaseURL + item.value" width="70" class="pl-2 pr-2"/>
                        <span>{{ item.text }}</span>
                    </template>
                </v-select>
            </v-col>

            <v-col cols="6" lg="6" sm="12" class="pl-7">
                <v-textarea v-model="scope.scope_details" class="scope-description" autocomplete="email" label="Short Description" height="313" :error-messages="scopeCreationErrors.details"></v-textarea>
            </v-col>
            <v-col cols="12" class="pl-7">
                <v-card-actions class="pt-2 pb-5">
                    <v-img :contain="true" :max-height="40" :src="loader" :position="'top center'" v-show="loading"></v-img>
                    <v-spacer />
                    <v-btn large class="px-8 py-2 white--text" color="blue-grey" v-if="!currentScopeID" @click="addScope()">Add Scope</v-btn>
                    <v-btn large class="px-8 py-2 white--text" color="blue-grey" v-if="currentScopeID" @click="createNewVersion(currentScopeID)">Create New Version</v-btn>
                    <v-btn large class="px-8 py-2 white--text" color="blue-grey" v-if="currentScopeID" @click="downloadScope()">Download</v-btn>
                    <v-btn large class="px-8 py-2 white--text" color="blue-grey" v-if="currentScopeID" @click="editScope()">Save Scope</v-btn>
                </v-card-actions>
            </v-col>
        </v-row>

        <v-col cols="12" v-if="currentScopeID">
            <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                <v-tab>Background &amp; Objective</v-tab>
                <v-tab>Stakeholders</v-tab>
                <v-tab>Scope</v-tab>
                <v-tab>Pricing</v-tab>
                <v-tab>Timeline</v-tab>
                <v-tab>Appendix</v-tab>
                <v-tab>Versions</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item><background-objective></background-objective></v-tab-item>
                <v-tab-item><stakeholder></stakeholder></v-tab-item>
                <v-tab-item><scope></scope></v-tab-item>
                <v-tab-item><planning></planning></v-tab-item>
                <v-tab-item><timeline></timeline></v-tab-item>
                <v-tab-item><appendix></appendix></v-tab-item>
                <v-tab-item><versions></versions></v-tab-item>
            </v-tabs-items>
        </v-col>
      </v-card-text>
  </v-card>

 </Layout>
</template>

<script>
import Layout from "@/shared/Layout";
import BackgroundObjective from "./components/BackgroundObjective";
import Stakeholder from "./components/Stakeholder";
import Scope from "./components/Scope";
import Planning from "./components/Planning";
import Timeline from "./components/Timeline";
import Appendix from "./components/Appendix";
import Versions from "./components/Versions";
import loader from "@/img/filter-loader.gif";
import { API_URL } from "../../common/config";

export default {
    components: {
        Layout,
        BackgroundObjective,
        Stakeholder,
        Scope,
        Planning,
        Timeline,
        Appendix,
        Versions
    },
    data() {
        return {
            window: window,
            imageBaseURL: API_URL + 'images/',
            tab: 0,
            scopeNotFound: false,
            scope: this.defaultScope(),
            loader: loader,
            loading: false,
            scope_available_status: [
                {
                    text: 'Draft',
                    value: 1
                },
                {
                    text: 'Bid Open',
                    value: 2
                },
                {
                    text: 'Bid Closed',
                    value: 3
                },
                {
                    text: 'Won',
                    value: 4
                },
                {
                    text: 'Lost',
                    value: 5
                }
            ],
            coverPhotos: [
                {
                    text: 'Minimal Tech',
                    value: 'minimal-tech.jpg'
                },
                {
                    text: 'Programmer Lease', 
                    value: 'programmer-lease.jpg'
                },
                {
                    text: 'Network Optimization',
                    value: 'network-optimization.jpg'
                },
                {
                    text: 'Business Consulting',
                    value: 'business-consulting.jpg'
                },
                {
                    text: 'Webshop',
                    value: 'webshop.jpg'
                },
                {
                    text: 'Default',
                    value: 'background.jpg'
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('user/fetchUserList').then((data)=>{
            // console.log(data);
        })
        .catch((response)=> {
            // console.log(response);
        });

        this.$store.dispatch('client/fetchClientList').then((data)=>{
            // console.log(data);
        })
        .catch((response)=> {
            // console.log(response);
        });
        if(typeof this.$route.params.scope_id != 'undefined'){
            this.$store.dispatch('scope/fetchScopeData', this.$route.params.scope_id).then((data)=>{
                //this.scope_data = data.success;
                this.setScopeData(data.success);
            })
            .catch((response)=> {
                this.scopeNotFound = true;
                // console.log(response);
            });
        }
    },
    beforeDestroy(){
        this.$store.commit('scope/setScopeID', {scope_id: 0});
        this.$store.commit('scope/clearErrors');
    },
    computed: {
        currentScopeID(){
            return this.$store.getters['scope/getScopeID'];
        },
        allUserList(){
            return this.$store.getters['user/getUserList'];
        },
        allClientList(){
            return this.$store.getters['client/getClientList'];
        },
        scopeCreationErrors(){
            var errors = this.$store.getters['scope/getErrors'];
            var error_data = {};

            if (typeof errors.errors == 'undefined') {
                return error_data;
            }

            error_data['title_error'] = typeof errors.errors.scope_title != 'undefined' ? errors.errors.scope_title[0] : '';
            error_data['user_error'] = typeof errors.errors.scope_user != 'undefined' ? errors.errors.scope_user[0] : '';
            error_data['cover_photo'] = typeof errors.errors.cover_photo != 'undefined' ? errors.errors.cover_photo[0] : '';
            error_data['client_error'] = typeof errors.errors.client_id != 'undefined' ? errors.errors.client_id[0] : '';
            error_data['details'] = typeof errors.errors.details != 'undefined' ? errors.errors.details[0] : '';
            error_data['scope_id'] = typeof errors.errors.scope_id != 'undefined' ? errors.errors.scope_id[0] : '';

            return error_data;
        },
        scope_heading(){
            return this.currentScopeID ? 'Edit Scope ' + this.scope.scope_title : 'Add New Scope';
        }
    },
    watch: {
        '$route.name': function (name) {
            if(name == 'newscope') {
                this.$store.commit('scope/setScopeID', {scope_id: 0});
                this.$store.commit('scope/clearErrors');
                this.scope = this.defaultScope();
            }
        }
    },
    methods: {
        addScope(){
            this.loading = true;
            this.scopeNotFound = false;
            var new_scope = this.scope;
            var scope_users = new_scope.scope_users.join(",");
            //var scope_clients = new_scope.scope_client.join(",");
            var scope_arguments = {
                scope_user: scope_users,
                status: new_scope.scope_status,
                details: new_scope.scope_details,
                client_id: new_scope.scope_client,
                scope_title: new_scope.scope_title,
                cover_photo: new_scope.cover_photo,
                deadline: new_scope.scope_deadline.date
            }
            this.$store.dispatch('scope/addNewScope', scope_arguments).then((data)=>{
                this.loading = false;
            })
            .catch((response)=> {
                this.loading = false;
                // console.log(response);
            });
        },
        editScope(){
            this.loading = true;
            var new_scope = this.scope;
            var scope_users = new_scope.scope_users.join(",");
            //var scope_clients = new_scope.scope_client.join(",");
            var scope_arguments = {
                scope_user: scope_users,
                scope_id: this.currentScopeID,
                status: new_scope.scope_status,
                details: new_scope.scope_details,
                client_id: new_scope.scope_client,
                scope_title: new_scope.scope_title,
                cover_photo: new_scope.cover_photo,
                deadline: new_scope.scope_deadline.date
            }
            this.$store.dispatch('scope/editScope', scope_arguments).then((data)=>{
                this.loading = false;
            })
            .catch((response)=> {
                this.loading = false;
            });
        },
        downloadScope(){
            var scope_id = this.currentScopeID;
            this.$store.dispatch('scope/downloadScope', {scope_id: scope_id}).then((data)=>{
                // console.log(data);
            })
            .catch((response)=> {
                // console.log(response);
            });
        },
        setScopeData(scope){
            this.scope.scope_title = scope.scope_title;
            this.scope.scope_users = scope.scope_user.split(",");
            //this.scope.scope_client = scope.client_id.split(",");
            this.scope.scope_client = scope.client_id.toString();
            this.scope.scope_deadline.date = new Date(scope.deadline).toISOString().substr(0, 10);
            this.scope.scope_status = scope.status;
            this.scope.scope_details = scope.details;
            this.scope.cover_photo = scope.cover_photo;
        },
        createNewVersion(scopeId){
            this.loading = true;
            this.$store.dispatch('version/createNewVersion', {scope_id: scopeId}).then((data)=>{
                this.loading = false;
            })
            .catch((response) => {
                this.loading = false;
                // console.log(response);
            });
        },
        defaultScope(){
            return {
                scope_title: '',
                scope_client: '',
                scope_status: 1,
                scope_users: [],
                //scope_creator: 1,
                scope_details: 'Scope details',
                cover_photo: 'background.jpg',
                scope_deadline: {
                    date: new Date().toISOString().substr(0, 10),
                    showDatepicker: false
                }
            }
        }
    }
}
</script>

<style>
.basil {
  /* background-color: #FFFBE6 !important; */
  background-color: #F2F5F7 !important;
}
.basil--text {
  color: #356859 !important;
}
.scope-description .v-input__slot {
    align-items: flex-start;
}
.v-text-field {
    padding-bottom: 15px;
}
</style>