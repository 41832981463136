export const API_URL = window.scopeManageBaseUrl;
export const TOKEN_KEY = 'secure_vault_token';
export const TOKEN_REFRESH_KEY = 'secure_vault_refresh_token';
export const TOKEN_REFRESH_EXPIRE = (60 * 60 * 24 * 7 * 2) - 10;
export const TOKEN_ROUTE = 'api/oauth/refresh-token';
export const COMPONENT_TYPES = [
    'Epic', 
    'Payment terms', 
    'Product details',
    'Terms and conditions'
];
export const DEFAULT_ACTOR = 'Default actor';
export const CKEDITOR_CONFIG = {
    format_h1: { element: "h1", attributes: { class: "text-h1" } },
    format_h2: { element: "h2", attributes: { class: "text-h2" } },
    format_h3: { element: "h3", attributes: { class: "text-h3" } },
    format_h4: { element: "h4", attributes: { class: "text-h4" } },
    format_h5: { element: "h5", attributes: { class: "text-h5" } },
    format_h6: { element: "h6", attributes: { class: "text-h6" } },
    extraPlugins: 'colorbutton,colordialog,easyimage',
    cloudServices_tokenUrl: 'https://76668.cke-cs.com/token/dev/6c755018ef03df8887927ed4fc0878b292d8d98afd23507bf86e75e26fe7',
    cloudServices_uploadUrl: 'https://76668.cke-cs.com/easyimage/upload/',
    easyimage_toolbar: ['EasyImageAlignLeft', 'EasyImageAlignCenter', 'EasyImageAlignRight', 'EasyImageFull', 'EasyImageSide', 'EasyImageAlt'],
    removeButtons: "Styles,Font,FontSize,Subscript,Superscript,Anchor,About",
    format_tags: "p;h1;h2;h3;h4;h5;h6;div",
    toolbarCanCollapse: true,
    height: 500
};

export default {
    API_URL,
    TOKEN_KEY,
    TOKEN_ROUTE,
    DEFAULT_ACTOR,
    COMPONENT_TYPES,
    CKEDITOR_CONFIG,
    TOKEN_REFRESH_KEY,
    TOKEN_REFRESH_EXPIRE
};