import ApiService from '../api/api.service';
import Errors from '../form';

const state = {
    errors: new Errors(),
    scope_stakeholders: []
};

const getters = {
    getErrors(state){
        return state.errors;
    },
    getScopeStakeholders(state){
        return state.scope_stakeholders;
    }
};

const mutations = {
    setError(state, {target, message}) {
        //console.log(message);
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setScopeStakeholders(state, scopeStakeholders){
        if(scopeStakeholders){
            state.scope_stakeholders = scopeStakeholders;
        }
    },
    setScopeStakeholder(state, scopeStakeholder){
        var stakeholder_index = state.scope_stakeholders.findIndex((stakeholder => stakeholder.st_id == scopeStakeholder.st_id));

        if(stakeholder_index > -1){
            state.scope_stakeholders.splice(stakeholder_index, 1, scopeStakeholder);
        }
    },
    addScopeStakeholder(state, scopeStakeholder) {
        var scopeStakeholders = state.scope_stakeholders;
            scopeStakeholders.push(scopeStakeholder);

        state.scope_stakeholders = scopeStakeholders;
    },
    removeScopeStakeholder(state, stakeholderId) {
        state.scope_stakeholders = state.scope_stakeholders.filter(stakeholder => stakeholder.st_id !== stakeholderId);
    }
}

const actions = {
    addNewStakeholder(context, stakeholder_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/create-stakeholder', {...stakeholder_arguments})
            .then(({data})=>{
                context.commit('addScopeStakeholder', data.success);
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'add_stakeholder', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchScopeStakeholders(context, scope_id){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/stakeholder-list', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setScopeStakeholders', data.success);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    updateScopeStakeholder(context, stakeholder_arguments) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/update-stakeholder', {...stakeholder_arguments}).then(({data})=>{
                context.commit('setScopeStakeholder', data.success);
                context.commit('clearErrors');
                resolve(data);
            }).catch(({response}) => {
                context.commit('setError', {target: 'update_stakeholder', message: response.data.error});
                reject(response);
            });
        });
    },
    deleteScopeStakeholder(context, stakeholder_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/delete-stakeholder', {st_id: stakeholder_id}).then(({data})=>{
                context.commit('removeScopeStakeholder', stakeholder_id);
                context.commit('clearErrors');
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};