import ApiService from '../api/api.service';
import router from '../router';
import Errors from '../form';

const state = {
    user_id: 0,
    allUsers: [],
    errors: new Errors(),
    fullUserList: [],
};

const getters = {
    getUserList(state){
        return state.allUsers;
    },
    getUserID(state){
        return state.user_id;
    },
    getErrors(state){
        return state.errors;
    },
    getFullUserList(state){
        return state.fullUserList;
    }
};

const mutations = {
    setUserList(state, data) {
        var userList = [];
        for (var user_id in data.user) {
            var user_item = {};
            user_item['value'] = user_id;
            user_item['text'] = data.user[user_id];
            userList.push(user_item);
        }
        state.allUsers = userList;
    },
    setUserID(state, data) {
        state.user_id = data.user_id;
    },
    setError(state, {target, message}) {
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setFullUserList(state, data){
        state.fullUserList = data;
    }
}

const actions = {
    fetchUserList(context) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/user-list').then(({data})=>{
                context.commit('setUserList', {user: data.success });
                //resolve(data);
            }).catch(error => {
                // console.log(error);
            });
        });
    },
    fetchUser(context, user_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/user-data', `?user_id=${user_id}`).then(({data})=>{
                context.commit('setUserID', {user_id: data.success.user_id });
                resolve(data);
            }).catch(error => {
                router.push('/users');
                reject(error);
            });
        });
    },
    resetUserID(context) {
        context.commit('setUserID', {user_id: 0});
    },
    addNewUser(context, user_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/create-user', {...user_arguments})
            .then(({data}) => {
                context.commit('clearErrors');
                context.commit('setUserID', {user_id: data.success.user_id});
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'create_user', message: response.data.error});
                reject(response);
            });
        });
    },
    updateUser(context, user_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/update-user', {...user_arguments})
            .then(({data}) => {
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'update_user', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchFullUserList(context){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/user-list-details').then(({data})=>{
                context.commit('setFullUserList', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
};