import ApiService from '../api/api.service';
import Errors from '../form';

const getDefaultTimeline = () => {
    return {
        dt_id: 0,
        timeline: `<p>This section describes the timeline overview for this project.</p> <br />
        <p>Number of Resources: 7 Front-end (FE) Developers</p> <p>4 Back-end (BE) Developers</p> 
        <p>2 Business Analysts</p> <p>1 Business Consultant</p> <p>1 UX Engineer</p> <p>Number of FTE Months: 1.73 
        (excluding ECAP core support)</p> <p>On-site Support: Not required</p> <p>Development Period: 01 November, 
        2020 - 25 December, 2020</p> <p>UAT Deployment &amp; Testing Period: 15 - 31 December, 2020</p> 
        <p>Production Deployment: 31 December, 2020</p><h2>Notes for Timeline</h2> <br /> <ul> <li> <p >During the development period, SELISE will, at 
        regular intervals, deploy software on SELISE-owned servers for customer&rsquo;s review and feedback.</p>
         </li> <li> <p >The client will need to confirm the requirements based on the documentation provided by SELISE.
          Any failure to provide confirmation of features prior to due date might result in features being dropped from 
          this release.&nbsp;</p> </li> <li> <p >UAT feedback items that require more time to implement will be carried 
          forward to the upcoming releases.</p> </li> <li> <p >Any changes in scope will invalidate this scope document 
          after it is signed. SELISE may not be able to deliver all features if the scope is not signed or is 
          changed upon further discoveries of the customer&rsquo;s request. All such cases may lead to 
          delayed development or deployment.</p> </li> </ul>`,
    }
};

const state = {
    errors: new Errors(),
    timeline_data:  getDefaultTimeline()
};

const getters = {
    getErrors(state){
        return state.errors;
    },
    getScopeTimeline(state){
        return state.timeline_data;
    }
};

const mutations = {
    setError(state, {target, message}) {
        //console.log(message);
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setScopeTimeline(state, timeline_data){
        if(timeline_data){
            state.timeline_data = timeline_data;
        } else {
            state.timeline_data = getDefaultTimeline();
        }
    },
    setTimelineData(state, timeline){
        state.timeline_data.timeline = timeline;
    }
};

const actions = {
    saveDevTimeline(context, timeline_arg){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/dev-timeline-save', {...timeline_arg})
            .then(({data})=>{
                //console.log(data);
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'timeline', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchTimelineData(context, scope_id){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/dev-timeline', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setScopeTimeline', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};