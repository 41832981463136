import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import AllScope from  './pages/Scope/AllScope';
import NewScope from  './pages/Scope/Add';
import NewUser from  './pages/Users/Add';
import AllUser from  './pages/Users/List';
import NewClient from  './pages/Clients/Add';
import AllClients from  './pages/Clients/List';
import NewComponent from  './pages/Component/Add';
import AllComponent from  './pages/Component/List';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/scope/',
            name: 'allscope',
            component: AllScope,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/scope/new',
            name: 'newscope',
            component: NewScope,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/scope/edit/:scope_id',
            name: 'editscope',
            component: NewScope,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/users/',
            name: 'listuser',
            component: AllUser,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/users/create',
            name: 'newuser',
            component: NewUser,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/user/edit/:user_id',
            name: 'edituser',
            component: NewUser,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/clients/',
            name: 'clientlist',
            component: AllClients,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/clients/create',
            name: 'newclient',
            component: NewClient,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/client/edit/:client_id',
            name: 'editclient',
            component: NewClient,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/components/',
            name: 'allcomponent',
            component: AllComponent,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/component/create',
            name: 'addcomponent',
            component: NewComponent,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/component/edit/:component_id',
            name: 'editcomponent',
            component: NewComponent,
            meta: {
                requiresAuth: true
            }
        },
    ],
});

router.beforeEach((to, from, next) => {
    next();

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['authentication/isAuthenticated']) {
            next();
        } else {
            next({ name: 'login' });
        }
    } else {
		if (to.name == 'login') {
			if (store.getters['authentication/isAuthenticated']) {
				next({name: 'dashboard'});
			}
		} else {
			next();
		}
    }

});

export default router;