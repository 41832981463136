import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import JwtService from "../common/jwt.service";
import {API_URL} from "../common/config";

Vue.use(VueAxios, axios);

var enableToken = false;

const ApiService = {
    setHeader(){
        enableToken = true;
        Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
    },

    async delete(resource) {
        if (enableToken) {
            let jwtToken = await JwtService.getToken();
            enableToken  = false;

            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
        }

        return Vue.axios.delete(`${API_URL}${resource}`).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    async get(resource, slug = "") {
        if (enableToken) {
            let jwtToken = await JwtService.getToken();
            enableToken  = false;

            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
        }

        return Vue.axios.get(`${API_URL}${resource}${slug}`);
    },

    async post(resource, params) {
        if (enableToken) {
            let jwtToken = await JwtService.getToken();
            enableToken  = false;

            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
        }

        return Vue.axios.post(`${API_URL}${resource}`, params);
    },
};

export default ApiService;