<template>
	<div class="all-description-text">
		<v-card color="basil" flat>
			<v-card class="d-flex justify-space-between align-center px-4 py-4" color="lighten-2" flat >
			<v-toolbar-title>Project Timeline</v-toolbar-title>
			<div class="text-left">
				<v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="existingProductDetailsDialog = true">Add Reuseable Product Details</v-btn>
			</div>
			</v-card>
			<v-card-text v-for="(productDetail, index) in product_details" :key="index">
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-text-field placeholder="Title" :value="productDetail.title" @input="productDetailTitleChanged($event, productDetail)"></v-text-field>
						</v-col>
						<v-col cols="12">
							<ckeditor :value="productDetail.details" :config="editorConfig" @input="productDetailsChanged($event, productDetail)"></ckeditor>
						</v-col>
					</v-row>
					<div class="d-flex justify-flex-start align-center py-3" flat >
					<div class="text-left">
						<v-card-actions>
							<v-btn large class="px-8 py-2 white--text" color="error" @click="removeProductDetails(productDetail.pd_id)">Remove Product Details</v-btn>
							<v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="updateProductDetails(productDetail)">Save Product Details</v-btn>
						</v-card-actions>
					</div>
					<div class="success-message" v-if="saveSuccess">
						<p>Data Updated Successfully</p>
					</div>
					</div>
				</v-container>
			</v-card-text>
			<v-card-text>
				<v-container>
					<v-row>
					<v-col cols="12">
						<h3 class="editor-label">Appendix</h3>
						<ckeditor v-model="scopeAppendix" :config="editorConfig"></ckeditor>
						<div class="editor-error-message" v-if="appendixError.appendix"><p>{{ appendixError.appendix}}</p></div>
					</v-col>
					</v-row>
					<div class="d-flex justify-flex-start align-center py-3" flat >
					<div class="text-left">
						<v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveScopeAppendix">Save</v-btn>
					</div>
					<div class="success-message" v-if="saveSuccess">
						<p>Data Updated Successfully</p>
					</div>
					</div>
				</v-container>
			</v-card-text>
		</v-card>

		<v-dialog v-model="existingProductDetailsDialog" persistent max-width="600px">
			<v-card>
			<v-card-title>
				<span class="headline">Select Existing Product Details</span>
			</v-card-title>
			<v-card-text>
				<v-container>
				<v-row>
					<v-col cols="12">
						<v-autocomplete v-model="selectedExistingProductDetails" :items="existing_product_details" filled label="Select Product Details" item-text="title" item-value="id"></v-autocomplete>
					</v-col>
				</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="existingProductDetailsDialog = false; selectedExistingProductDetails = 0">Close</v-btn>
				<v-btn color="blue darken-1" text @click="addProductDetails(selectedExistingProductDetails)">Add</v-btn>
			</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { CKEDITOR_CONFIG } from '../../../common/config';

export default {
    data() {
        return {
			saveSuccess: false,
			editorConfig: CKEDITOR_CONFIG,
			selectedExistingProductDetails: 0,
			existingProductDetailsDialog: false
        };
    },
    mounted() {
        var scope_id = this.$store.getters['scope/getScopeID'];
        var self = this;
        this.$store.dispatch('appendix/fetchAppendixData', scope_id).then((data)=>{
            // console.log(data.success);
        })
        .catch((response)=> {
            // console.log(response);
		});
        this.$store.dispatch('appendix/fetchProductDetailsData', scope_id).then((data)=>{
            // console.log(data.success);
        })
        .catch((response)=> {
            // console.log(response);
		});
		this.$store.dispatch('appendix/fetchExistingProductDetailsData').then((data)=>{
			//this.setScopeData(data.success);
		})
		.catch((response)=> {
			// console.log(response);
		});
    },
    computed: {
        ...mapState('appendix', ['appendix_data', 'existing_product_details', 'product_details']),
        currentScopeID(){
            return this.$store.getters['scope/getScopeID'];
        },
        appendixError(){
            var errors = this.$store.getters['appendix/getErrors'];
			var error_data = {};

			if (typeof errors.errors == 'undefined') {
                return error_data;
			}
			
            error_data['appendix'] = typeof errors.errors.appendix != 'undefined' ? errors.errors.appendix[0] : '';
            return error_data;
        },
        scopeAppendix: {
            get(){
                return this.appendix_data.appendix;
            },
            set(appendix){
                this.$store.commit('appendix/setAppendixData', appendix);
            }
		}
    },
    methods: {
        saveScopeAppendix(){
            var self = this;
            this.saveSuccess = false;
            var scope_appendix = {
                appendix: this.scopeAppendix,
                scope_id: this.currentScopeID,
            };
            this.$store.dispatch('appendix/saveAppendix', scope_appendix).then((data)=>{
                this.saveSuccess = true; 
                setTimeout(function(){
                    self.saveSuccess = false;
                }, 3000);    
            })
            .catch((response)=> {
                // console.log(response);
            });
		},
		addProductDetails(productDetailsID){
			var existingProductDetails = this.existing_product_details.find(productDetails => productDetails.id == productDetailsID);
			var self = this;
            var productDetailsData = {
				scope_id: this.currentScopeID,
				title: existingProductDetails.title,
                details: existingProductDetails.json_dump
            };
            this.$store.dispatch('appendix/addProductDetails', productDetailsData).then((data)=>{
				setTimeout(function(){
					this.selectedExistingProductDetails = 0;
					self.existingProductDetailsDialog = false; 
                }, 500);    
            })
            .catch((response)=> {
                // console.log(response);
            });
		},
		updateProductDetails(product_details){
			var self = this;

            this.$store.dispatch('appendix/editProductDetails', product_details).then((data)=>{
				self.saveSuccess = true;
				setTimeout(function(){
					self.saveSuccess = false;
                }, 1000);    
            })
            .catch((response)=> {
                // console.log(response);
            });
		},
		removeProductDetails(productDetailsId){
			this.$store.dispatch('appendix/deleteProductDetails', productDetailsId).then((data)=>{
				// console.log(response);  
            })
            .catch((response)=> {
                // console.log(response);
            });
		},
		productDetailTitleChanged(productDetailTitle, productDetailsData){
			var productDetails = {
				title: productDetailTitle,
				pd_id: productDetailsData.pd_id,
				details: productDetailsData.details,
				scope_id: productDetailsData.scope_id,
				created_at: productDetailsData.created_at,
				created_by: productDetailsData.created_by
			}

			this.$store.commit('appendix/updateProductDetails', productDetails);
		},
		productDetailsChanged(productDetails, productDetailsData){
			var productDetails = {
				details: productDetails,
				title: productDetailsData.title,
				pd_id: productDetailsData.pd_id,
				scope_id: productDetailsData.scope_id,
				created_at: productDetailsData.created_at,
				created_by: productDetailsData.created_by
			}

			this.$store.commit('appendix/updateProductDetails', productDetails);
		}
    }
}
</script>

