import Errors from '../form';
import ApiService from '../api/api.service';

const state = {
    errors: new Errors(),
    scope_versions: []
};

const getters = {
    getErrors(state){
        return state.errors;
    },
    getScopeVersions(state){
        return state.scope_versions;
    }
};

const mutations = {
    setError(state, {target, message}) {
        state.errors.clear();
        state.errors.record(message);
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setScopeVersions(state, versions){
        if(versions){
            state.scope_versions = versions;
        }
    },
    addScopeVersion(state, version) {
        var versions = state.scope_versions;
            // versions.push(version);
            versions.unshift(version);

        state.scope_versions = versions;
    }
};

const actions = {
    createNewVersion(context, scope_arguments) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/create-scope-version', {...scope_arguments}).then(({data})=>{
                context.commit('addScopeVersion', data.success);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    fetchScopeVersions(context, scope_id){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/scope-versions', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setScopeVersions', data.success);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    restoreScopeVersion(context, scope_arguments) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/restore-scope', {...scope_arguments}).then(({data})=>{
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};