<template>
    <Layout>
        <v-card>
            <v-alert type="warning" v-if="notFound">
                The Component you trying to edit can't be found. Please add a new component.
            </v-alert>
            <v-card-title>Add Component</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col class="d-flex" cols="6" sm="6">
                        <v-select :items="componentTypes" label="Select component type" v-model="component.type" :error-messages="componentCreationErrors.type_error" solo @change="clearErrors()"></v-select>
                    </v-col>
                </v-row>
                <v-row class="pb-12" v-show="epicEnable">
                    <v-col class="d-flex" cols="12">
                        <scopeEpic ref="scopeEpic"></scopeEpic>
                    </v-col>
                </v-row>
                <v-row class="pb-7" v-show="productDetailsEnable">
                    <v-col class="d-flex" cols="12">
                        <productDetails ref="productDetails"></productDetails>
                    </v-col>
                </v-row>
                <v-row class="pb-7" v-show="paymentTermsEnable">
                    <v-col class="d-flex" cols="12">
                        <paymentTerms ref="paymentTerms"></paymentTerms>
                    </v-col>
                </v-row>
                <v-row class="pb-7" v-show="termsAndConditionsEnable">
                    <v-col class="d-flex" cols="12">
                        <termsAndConditions ref="termsAndConditions"></termsAndConditions>
                    </v-col>
                </v-row>
                <v-row class="pb-7">
                    <v-col cols="12">
                        <v-card-actions class="pt-2 pb-5 d-flex justify-end">
                            <v-img :contain="true" :max-height="40" :src="loader" :position="'top center'" v-show="loading">
                            </v-img>
                            <div v-if="(add_success && !currentComponentID)">
                                <v-alert type="success" class="mb-0 user-success-alert">
                                    Component created successfully
                                </v-alert>
                            </div>
                            <div v-else-if="(add_success && currentComponentID)">
                                <v-alert type="success" class="mb-0 user-success-alert">
                                    Component updated successfully
                                </v-alert>
                            </div>
                            <v-btn large class="px-8 py-2 ml-8 white--text" color="blue-grey" v-if="!currentComponentID" @click="addNewComponent">Add Component</v-btn>
                            <v-btn large class="px-8 py-2 ml-8 white--text" color="blue-grey" v-if="currentComponentID" @click="updateComponent">Update Component</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </Layout>
</template>

<script>
import Layout from "@/shared/Layout";
import loader from "@/img/filter-loader.gif";
import scopeEpic from "./components/scopeEpic";
import productDetails from "./components/productDetails";
import paymentTerms from "./components/paymentTerms";
import termsAndConditions from "./components/termsAndConditions";
import {COMPONENT_TYPES} from "../../common/config";

export default {
    components: {
        Layout,
        scopeEpic,
        paymentTerms,
        productDetails,
        termsAndConditions
    },
    data() {
        return {
            notFound: false,
            componentTypes: COMPONENT_TYPES,
            component: this.defaultComponentFormData(),
            loader: loader,
            loading: false,
            add_success: false
        }
    },
    created() {
        this.fetchCurrentComponent();
    },
    computed: {
        epicEnable() {
            return this.component.type === COMPONENT_TYPES[0];
        },
        paymentTermsEnable() {
            return this.component.type === COMPONENT_TYPES[1];
        },
        productDetailsEnable() {
            return this.component.type === COMPONENT_TYPES[2];
        },
        termsAndConditionsEnable() {
            return this.component.type === COMPONENT_TYPES[3];
        },
        currentComponentID(){
            return this.$store.getters['component/getComponentID'];
        },
        componentCreationErrors(){
            var errors = this.$store.getters['component/getErrors'];
            var error_data = {};

            if (typeof errors.errors == 'undefined') {
              return error_data;
            }

            error_data['type_error'] = typeof errors.errors.type != 'undefined' ? errors.errors.type[0] : '';
            error_data['title_error'] = typeof errors.errors.title != 'undefined' ? errors.errors.title[0] : '';
            error_data['description_error'] = typeof errors.errors.json_dump != 'undefined' ? errors.errors.json_dump[0] : '';

            return error_data;
        }
    },
    watch: {
        '$route' (to, from) {
            this.fetchCurrentComponent();
        }
    },
    methods: {
        fetchCurrentComponent() {
            if(typeof this.$route.params.component_id != 'undefined'){
                this.$store.dispatch('component/fetchComponent', this.$route.params.component_id).then((data)=>{
                    if (data.success) {
                        this.setComponent(data.success);

                        if (this.epicEnable) {
                            this.$refs.scopeEpic.setScopeEpic(data.success);
                        } else if (this.productDetailsEnable) {
                            this.$refs.productDetails.setProductDetails(data.success);
                        } else if(this.paymentTermsEnable) {
                            this.$refs.paymentTerms.setPaymentTerms(data.success);
                        } else if(this.termsAndConditionsEnable) {
                            this.$refs.termsAndConditions.setTermsAndConditions(data.success);
                        }
                    } else {
                        this.resetCurrentComponent();
                    }
                })
                .catch(({response})=> {
                    this.notFound = true;
                    this.resetCurrentComponent();
                });
            } else {
                this.resetCurrentComponent();
            }
        },
        resetCurrentComponent() {
            this.$store.dispatch('component/resetComponentID');
            this.setComponent(this.defaultComponentFormData());
        },
        addNewComponent(){            
            var self = this;
            var new_component = this.component;
            this.loading = true;

            if (this.epicEnable) {
                this.$refs.scopeEpic.storeScopeEpic();

                var scopeEpic = this.$store.getters['component/getScopeEpic'];
                new_component.title = scopeEpic.title;
                new_component.type = new_component.type;
                new_component.json_dump = scopeEpic.json_dump;
            } else if (this.productDetailsEnable) {
                this.$refs.productDetails.storeProductDetails();

                var productDetails = this.$store.getters['component/getProductDetails']
                new_component.title = productDetails.title;
                new_component.type = new_component.type;
                new_component.json_dump = productDetails.json_dump;
            } else if (this.paymentTermsEnable) {
                this.$refs.paymentTerms.storePaymentTerms();

                var paymentTerms = this.$store.getters['component/getPaymentTerms']
                new_component.title = paymentTerms.title;
                new_component.type = new_component.type;
                new_component.json_dump = paymentTerms.json_dump;
            } else if (this.termsAndConditionsEnable) {
                this.$refs.termsAndConditions.storeTermsAndConditions();

                var termsAndConditions = this.$store.getters['component/getTermsAndConditions']
                new_component.title = termsAndConditions.title;
                new_component.type = new_component.type;
                new_component.json_dump = termsAndConditions.json_dump;
            }


            this.$store.dispatch('component/addNewComponent', new_component).then((data)=>{
                self.loading = false;
                self.add_success = true;
                setTimeout(function(){
                    self.add_success = false;
                }, 3000);

                self.$refs.scopeEpic.setScopeEpicErrors(self.componentCreationErrors);
                self.$refs.productDetails.setProductDetailsErrors(self.componentCreationErrors);
                self.$refs.paymentTerms.setPaymentTermsErrors(self.componentCreationErrors);
                self.$refs.termsAndConditions.setTermsAndConditionsErrors(self.componentCreationErrors);
            })
            .catch(({response})=> {
                self.loading = false;

                self.$refs.scopeEpic.setScopeEpicErrors(self.componentCreationErrors);
                self.$refs.productDetails.setProductDetailsErrors(self.componentCreationErrors);
                self.$refs.paymentTerms.setPaymentTermsErrors(self.componentCreationErrors);
                self.$refs.termsAndConditions.setTermsAndConditionsErrors(self.componentCreationErrors);
            });
        },
        setComponent(data) {
            this.component = data;
        },
        updateComponent(){            
            var self = this;
            var old_component = this.component;
            this.loading = true;

            if (this.epicEnable) {
                this.$refs.scopeEpic.storeScopeEpic();

                var scopeEpic = this.$store.getters['component/getScopeEpic'];
                old_component.component_id = this.currentComponentID;
                old_component.title = scopeEpic.title;
                old_component.type = old_component.type;
                old_component.json_dump = scopeEpic.json_dump;
            } else if (this.productDetailsEnable) {
                this.$refs.productDetails.storeProductDetails();

                var productDetails = this.$store.getters['component/getProductDetails']
                old_component.component_id = this.currentComponentID;
                old_component.title = productDetails.title;
                old_component.type = old_component.type;
                old_component.json_dump = productDetails.json_dump;
            } else if (this.paymentTermsEnable) {
                this.$refs.paymentTerms.storePaymentTerms();

                var paymentTerms = this.$store.getters['component/getPaymentTerms']
                old_component.component_id = this.currentComponentID;
                old_component.title = paymentTerms.title;
                old_component.type = old_component.type;
                old_component.json_dump = paymentTerms.json_dump;
            } else if (this.termsAndConditionsEnable) {
                this.$refs.termsAndConditions.storeTermsAndConditions();

                var termsAndConditions = this.$store.getters['component/getTermsAndConditions']
                old_component.component_id = this.currentComponentID;
                old_component.title = termsAndConditions.title;
                old_component.type = old_component.type;
                old_component.json_dump = termsAndConditions.json_dump;
            }

            this.$store.dispatch('component/updateComponent', old_component).then((data)=>{
                self.loading = false;
                self.add_success = true;
                setTimeout(function(){
                    self.add_success = false;
                }, 3000);

                self.$refs.scopeEpic.setScopeEpicErrors(self.componentCreationErrors);
                self.$refs.productDetails.setProductDetailsErrors(self.componentCreationErrors);
                self.$refs.paymentTerms.setPaymentTermsErrors(self.componentCreationErrors);
                self.$refs.termsAndConditions.setTermsAndConditionsErrors(self.componentCreationErrors);
            })
            .catch(({response})=> {
                self.loading = false;

                self.$refs.scopeEpic.setScopeEpicErrors(self.componentCreationErrors);
                self.$refs.productDetails.setProductDetailsErrors(self.componentCreationErrors);
                self.$refs.paymentTerms.setPaymentTermsErrors(self.componentCreationErrors);
                self.$refs.termsAndConditions.setTermsAndConditionsErrors(self.componentCreationErrors);
            });
        },
        defaultComponentFormData(){
            return {
                title: '',
                type: '',
                json_dump: ''
            }
        },
        clearErrors() {
            this.$store.commit('component/clearErrors');
        }
    }
}
</script>