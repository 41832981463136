<template>
  <v-card color="basil" flat>
    <v-card class="d-flex justify-space-between align-center px-4 py-4" color="lighten-2" flat >
      <v-toolbar-title>Development Pricing</v-toolbar-title>
    </v-card>

    <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">Pricing Overview</span></v-card-title>
            <ckeditor v-model="pricing_overview" :config="editorConfig"></ckeditor>
          </v-col>
          <div class="d-flex justify-flex-start align-flex-start mb-7">
            <div class="text-left ml-3">
              <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDevPricing('pricing_overview')">Save</v-btn>
            </div>
            <div class="success-message ml-5" v-if="success.pricing_overview">
              <p>Data Updated Successfully</p>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">Pricing Breakdown</span></v-card-title>
            <v-card-text class="px-0 py-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Epics</th>
                      <th class="text-left">Pricing Remarks</th>
                      <th class="text-left">Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(breakdown, breakdown_index) in orderedBreakdown" :key="breakdown.pb_id">
                      <td>3.1.{{ breakdown_index + 1 }}</td>
                      <td>{{ breakdown.epic ? breakdown.epic : breakdown.epic_title }}</td>
                      <td>{{ breakdown.remarks }}</td>
                      <td>{{ pricingFormat(breakdown.price) }}</td>
                      <td>
                        <v-card-actions>
                          <v-btn class="ma-2" small outlined color="error" @click="deletePriceBreakdown(breakdown.pb_id)" :disabled="breakdown.epic_id != null">
                            <v-icon small left>mdi-delete</v-icon> remove
                          </v-btn>
                          <v-btn class="ma-2" small outlined color="primary" @click="editBreakdownDialog(breakdown)">
                            <v-icon small left>mdi-pencil</v-icon> edit
                          </v-btn>
                        </v-card-actions>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Total Price</td>
                      <td>Excluding VAT</td>
                      <td>{{ sumPrice }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-dialog v-model="pricebreak_add_dialog" persistent max-width="600px">
                <template v-slot:activator="{ on }">
                  <div class="text-right">
                    <v-btn class="ma-2 white--text mt-4" color="blue-grey" v-on="on">
                      <v-icon left>mdi-plus</v-icon> Add New line
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Add new pricing</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="Epic" v-model="pricing_break_add.epic_title" :error-messages="priceBreakdownError.title" :readonly="pricing_break_add.from_epic"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="Pricing Remark" v-model="pricing_break_add.remarks" error-messages=""></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="Price" v-model.number="pricing_break_add.price" error-messages=""></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closePriceBreakDialog()">Close</v-btn>
                    <v-btn color="blue darken-1" text @click="savePricingBreakdown()">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">SAAS Calculator</span></v-card-title>
            <v-checkbox v-model="saas_model.is_active" :label="`Saas Pricing is: ${saas_model.is_active ? 'Active' : 'Inactive'}`"></v-checkbox>
            <div v-if="saas_model.is_active">
              <v-text-field label="Title" v-model="saas_model.title" :error-messages="saasModelError.title"></v-text-field>
              <v-text-field label="Description" v-model="saas_model.description" :error-messages="saasModelError.description"></v-text-field>
              <v-text-field label="Payment Period" v-model="saas_model.payment_period" :error-messages="saasModelError.paymentPeriod"></v-text-field>
              <v-text-field label="Downpayment" v-model="saas_model.downpayment" :error-messages="saasModelError.downpayment"></v-text-field>
              <v-text-field label="Interest Rate" v-model="saas_model.interest_rate" :error-messages="saasModelError.interestRate"></v-text-field>
              <v-textarea label="Termination" v-model="saas_model.termination" :error-messages="saasModelError.termination"></v-textarea>
              <div v-html="saasCalculator"></div>
            </div>
          </v-col>
          <div cols="12">
            <div class="text-left ml-3">
              <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveSaasModel(saas_model)">Save</v-btn>
            </div>
            <div class="success-message" v-if="success.saas_model">
              <p>Data Updated Successfully</p>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">Payment terms</span></v-card-title>
            <ckeditor v-model="payment_terms" :config="editorConfig"></ckeditor>
          </v-col>
          <div class="d-flex justify-flex-start align-flex-start mb-7">
            <div class="text-left ml-3">
              <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDevPricing('payment_terms')">Save</v-btn>
            </div>
            <div class="success-message ml-5" v-if="success.payment_terms">
              <p>Data Updated Successfully</p>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">Pricing Note</span></v-card-title>
            <ckeditor v-model="pricing_note" :config="editorConfig"></ckeditor>
          </v-col>
          <div class="d-flex justify-flex-start align-flex-start mb-7">
            <div class="text-left ml-3">
              <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDevPricing('pricing_note')">Save</v-btn>
            </div>
            <div class="success-message ml-5" v-if="success.pricing_note">
              <p>Data Updated Successfully</p>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">New Requirements &amp; Change Request</span></v-card-title>
            <ckeditor v-model="change_request" :config="editorConfig"></ckeditor>
          </v-col>
          <div class="d-flex justify-flex-start align-flex-start mb-7">
            <div class="text-left ml-3">
              <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDevPricing('change_request')">Save</v-btn>
            </div>
            <div class="success-message ml-5" v-if="success.change_request">
              <p>Data Updated Successfully</p>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">Handover, Acceptance and Customer Participation</span></v-card-title>
            <ckeditor v-model="handover" :config="editorConfig"></ckeditor>
          </v-col>
          <div class="d-flex justify-flex-start align-flex-start mb-7">
            <div class="text-left ml-3">
              <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDevPricing('handover')">Save</v-btn>
            </div>
            <div class="success-message ml-5" v-if="success.handover">
              <p>Data Updated Successfully</p>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title><span class="headline">Disclaimer</span></v-card-title>
            <ckeditor v-model="disclaimer" :config="editorConfig"></ckeditor>
          </v-col>
          <div class="d-flex justify-flex-start align-flex-start mb-7">
            <div class="text-left ml-3">
              <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDevPricing('disclaimer')">Save</v-btn>
            </div>
            <div class="success-message ml-5" v-if="success.disclaimer">
              <p>Data Updated Successfully</p>
            </div>
          </div>
        </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { CKEDITOR_CONFIG } from '../../../common/config';

export default {
  data(){
    return {
      pricebreak_add_dialog: false,
      epic_list: 1,
      payment_terms: '',
      change_request: '',
      handover: '',
      disclaimer: '',
      pricing_note: '',
      pricing_overview: '',
      pricing_break_add: this.defaultPricingBreak(),
      editorConfig: CKEDITOR_CONFIG,
      saas_model: {},
      saasCalculator: '',
      errors: {
        breakdown_error: {},
        saas_error: {}
      },
      success: {
        pricing_overview: false,
        payment_terms: false,
        change_request: false,
        pricing_note: false,
        handover: false,
        disclaimer: false,
        saas_model: false
      }
    }
  },
  mounted() {
    var self = this;
    var pricing_data = this.$store.getters['planning/getPricingData'];

    if(pricing_data.scope_id != this.currentScopeID){
      this.$store.dispatch('planning/fetchEpicPricingBreakdown', this.currentScopeID).then((data)=>{
        // console.log(data);
        var waitCKEDITOR = setInterval(() => {
          if (window.CKEDITOR) {
            clearInterval(waitCKEDITOR);
            if (data.success) {
              self.setPricingInfo(data.success);
            } else {
              self.setPricingInfo(pricing_data);
            }
          }
        }, 800);
      })
      .catch((response)=> {
        // console.log(response);
      });
    } else {
      var waitCKEDITOR = setInterval(() => {
        if (window.CKEDITOR) {
          clearInterval(waitCKEDITOR);
          this.setPricingInfo(pricing_data);
        }
      }, 800);
    }

    this.$store.dispatch('planning/fetchSaasModel', this.currentScopeID).then((data)=>{
      // console.log(data);
      self.setSaasModel(self.$store.getters['planning/getSaasModelData']);
    })
    .catch((response)=> {
      // console.log(response);
      self.setSaasModel(self.$store.getters['planning/getSaasModelData']);
    });
  },
  computed: {
    currentScopeID(){
      return this.$store.getters['scope/getScopeID'];
    },
    orderedBreakdown(){
      return this.$store.getters['planning/getOrderedBreakdown'];
    },
    sumPrice(){
      //var price_sum = this.$store.getters['planning/getPriceSum'];
      return 'CHF ' + this.$store.getters['planning/getPriceSum'];
    },
    priceBreakdownError(){
      var error_data = {};
      if (typeof this.errors.breakdown_error == 'undefined') {
        return error_data;
      }

      error_data['title'] = typeof this.errors.breakdown_error.epic_title != 'undefined' ? this.errors.breakdown_error.epic_title[0] : '';
      return error_data;
    },
    saasModelError(){
      var error_data = {};
      if (typeof this.errors.saas_error == 'undefined') {
        return error_data;
      }

      error_data['title'] = typeof this.errors.saas_error.title != 'undefined' ? this.errors.saas_error.title[0] : '';
      error_data['description'] = typeof this.errors.saas_error.description != 'undefined' ? this.errors.saas_error.description[0] : '';
      error_data['paymentPeriod'] = typeof this.errors.saas_error.payment_period != 'undefined' ? this.errors.saas_error.payment_period[0] : '';
      error_data['downpayment'] = typeof this.errors.saas_error.downpayment != 'undefined' ? this.errors.saas_error.downpayment[0] : '';
      error_data['interestRate'] = typeof this.errors.saas_error.interest_rate != 'undefined' ? this.errors.saas_error.interest_rate[0] : '';
      error_data['termination'] = typeof this.errors.saas_error.termination != 'undefined' ? this.errors.saas_error.termination[0] : '';
      return error_data;
    },
  },
  watch: {
    saas_model: {
      handler(saasModel){
        var totalPrice = Number.parseFloat(this.sumPrice.replace('CHF ', ''));
        var downpayment = Number.parseFloat(saasModel.downpayment);
        var interestRate = Number.parseFloat(saasModel.interest_rate);
        var paymentPeriod = Number.parseFloat(saasModel.payment_period);
        var remainingTotal = (totalPrice - downpayment) * Math.pow((1.00 + (interestRate / 100.00)), paymentPeriod);

        var monthlyInstallment = remainingTotal / paymentPeriod;
            monthlyInstallment = 'CHF ' + monthlyInstallment.toFixed(2) + '/mth';
        var yearlyInstallment = remainingTotal / (paymentPeriod / 12);
            yearlyInstallment = paymentPeriod < 24.00 ? 'N/A' : 'CHF ' + yearlyInstallment.toFixed(2) + '/year';

        this.saasCalculator = `
          <p><b>Installment (if paid monthly):</b> <span>${monthlyInstallment}</span></p>
          <p><b>Installment (if paid Yearly):</b> <span>${yearlyInstallment}</span></p>`;
      },
      deep: true
    }
  },
  methods: {
    setPricingInfo(pricing_info){
      this.pricing_overview = pricing_info.overview;
      this.payment_terms = pricing_info.payment_terms;
      this.change_request = pricing_info.change_request;
      this.pricing_note = pricing_info.note;
      this.handover = pricing_info.handover;
      this.disclaimer = pricing_info.disclaimer;
    },
    setSaasModel(saas_model){
      this.saas_model = {...saas_model};
    },
    defaultPricingBreak(){
      return {
        breakdown_id: '',
        pricing_id: '',
        epic_id: '',
        epic_title: '',
        remarks: '',
        price: '',
        from_epic: false
      }
    },
    closePriceBreakDialog(){
      this.pricebreak_add_dialog = false;
      this.pricing_break_add = this.defaultPricingBreak();
      this.errors.breakdown_error = {};
    },
    savePricingBreakdown(){
      var breakdown_data = {
        dp_id: this.$store.getters['planning/getPricingID'],
        pb_id: this.pricing_break_add.breakdown_id,
        price: this.pricing_break_add.price,
        remarks: this.pricing_break_add.remarks,
        epic_title: this.pricing_break_add.epic_title,
        from_epic: this.pricing_break_add.from_epic
      };
      this.$store.dispatch('planning/savePricingBreakdown', breakdown_data).then((data)=>{
        this.closePriceBreakDialog();
        // console.log(data);
      })
      .catch((response)=> {
        console.log(response);
        this.errors.breakdown_error = response.data.error;
      });
    },
    saveDevPricing(field_key){
      var pricing_data = {
        scope_id: this.currentScopeID,
        overview: this.pricing_overview,
        payment_terms: this.payment_terms,
        change_request: this.change_request,
        note: this.pricing_note,
        handover: this.handover,
        disclaimer: this.disclaimer
      };
      var self = this;
      this.$store.dispatch('planning/saveDevPricing', pricing_data).then((data)=>{
        self.success[field_key] = true;
        setTimeout(function(){
          self.success[field_key] = false;
        }, 3000);
      })
      .catch((response)=> {
        //console.log(response);
      });
    },
    editBreakdownDialog(breakdown){
      this.pricing_break_add.pricing_id = breakdown.dp_id;
      this.pricing_break_add.breakdown_id = breakdown.pb_id;
      this.pricing_break_add.remarks = breakdown.remarks ? breakdown.remarks : '';
      this.pricing_break_add.price = breakdown.price;
      if(breakdown.epic_id){
        this.pricing_break_add.from_epic = true;
        this.pricing_break_add.epic_title = breakdown.epic_data.title;
      } else {
        this.pricing_break_add.epic_title = breakdown.epic;
      }
      this.pricebreak_add_dialog = true;
    },
    deletePriceBreakdown(priceBreakdownId){
      this.$store.dispatch('planning/deletePricingBreakdown', priceBreakdownId).then((data)=>{
          //console.log(data);
      })
      .catch((response)=> {
          //console.log(response);
      });
    },
    pricingFormat(price){
      if(price) {
        return 'CHF ' + price;
      } else {
        return '';
      }
    },
    saveSaasModel(saasModel) {
      var self = this;
      saasModel.scope_id = this.currentScopeID;
      this.$store.dispatch('planning/saveSaasModel', saasModel).then((data)=>{
        self.success.saas_model = true;
        setTimeout(function(){
          self.success.saas_model = false;
        }, 3000);
        self.setSaasModel(self.$store.getters['planning/getSaasModelData']);
      })
      .catch((response)=> {
        // console.log(response);
        this.errors.saas_error = response.data.error;
      });
    }
  }
}
</script>

<style>

</style>