import Vue from 'vue';
import App from './pages/App';
import vuetify from '@/vuetify';
import router from './router';
import store from './store';
import CKEditor from 'ckeditor4-vue/dist/legacy.js';
import VueCookies from 'vue-cookies';

Vue.use(CKEditor);
Vue.use(VueCookies);

let app = document.getElementById('app');

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
