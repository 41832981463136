<template>
  <v-card color="basil" flat>
    <v-card class="d-flex justify-space-between align-center px-4 py-4" color="lighten-2" flat >
      <v-toolbar-title>Project Timeline</v-toolbar-title>
    </v-card>
    <v-card-text>
      <v-container>
         <v-row>
          <v-col cols="12">
            <h3 class="editor-label">Development Timeline</h3>
            <ckeditor v-model="developmentTimeline" :config="editorConfig"></ckeditor>
            <div class="editor-error-message" v-if="timeLineError.timeline"><p>{{ timeLineError.timeline }}</p></div>
          </v-col>
         </v-row>
        <div class="d-flex justify-flex-start align-center py-3" flat >
          <div class="text-left">
            <v-btn large class="px-8 py-2 white--text" color="blue-grey" @click="saveDevelopmentTimeline">Save</v-btn>
          </div>
          <div class="success-message" v-if="saveSuccess">
            <p>Data Updated Successfully</p>
          </div>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { CKEDITOR_CONFIG } from '../../../common/config';

export default {
    mounted() {
        var scope_id = this.$store.getters['scope/getScopeID'];
        var self = this;
        this.$store.dispatch('timeline/fetchTimelineData', scope_id).then((data)=>{
            
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    data() {
        return {
            saveSuccess: false,
            editorConfig: CKEDITOR_CONFIG,
        };
    },
    computed: {
        ...mapState('timeline', ['timeline_data']),
        currentScopeID(){
            return this.$store.getters['scope/getScopeID'];
        },
        timeLineError(){
            var errors = this.$store.getters['timeline/getErrors'];
            var error_data = {};

            if (typeof errors.errors == 'undefined') {
                return error_data;
            }

            error_data['timeline'] = typeof errors.errors.timeline != 'undefined' ? errors.errors.timeline[0] : '';
            return error_data;
        },
        developmentTimeline: {
            get(){
                //return this.$store.getters['documentOverview/getScopeDocOverview'].about;
                return this.timeline_data.timeline;
            },
            set(timeline){
                this.$store.commit('timeline/setTimelineData', timeline);
            }
        },
    },
    methods: {
        saveDevelopmentTimeline(){
            var self = this;
            this.saveSuccess = false;
            var dev_timeline = {
                timeline: this.developmentTimeline,
                scope_id: this.currentScopeID,
            };
            this.$store.dispatch('timeline/saveDevTimeline', dev_timeline).then((data)=>{
                this.saveSuccess = true; 
                setTimeout(function(){
                    self.saveSuccess = false;
                }, 3000);   
            })
            .catch((response)=> {
                // console.log(response);
            });
        }
    }
}
</script>

