import ApiService from '../api/api.service';
import {COMPONENT_TYPES} from '../common/config';

const getDefaultEpicFeature = () => {
    return {
        eps_id: '',
        background: `<p>The following section contains all the details discussed and explored in the duration of the 
        project. Every Feature also contains their specific assumption and dependencies, risk and what is deemed to be
         out of scope. Any feature specifically not mentioned in this document, will be considered as out of scope.</p>`,
        epic_table: [],
        get_epic: []
    }
};

const state = {
    epic_data : getDefaultEpicFeature(),
    existing_epics : []
};

const getters = {
    getScopeActors(state) {
        return state.epic_data.epic_table;
    }
};

const mutations = {
    setEpicData(state, epic_data) {
        if(epic_data){
            state.epic_data = epic_data;
        } else {
            state.epic_data = getDefaultEpicFeature();
        }
    },
    setExistingEpicData(state, epic_data) {
        if (epic_data.length) {
            const oldEpics = [];

            epic_data.forEach(function (epic, index) {
                var jsonDumpDecode = JSON.parse(epic.json_dump);
                var epicDataDecoded = jsonDumpDecode.epicData;
                var epicBreakdownDecoded = jsonDumpDecode.epicBreakdown;

                oldEpics.push({
                    id: epic.id,
                    title: epic.title,
                    epicData: epicDataDecoded,
                    epicBreakdown: epicBreakdownDecoded,
                });
            });

            state.existing_epics = oldEpics;
        }
    },
    setFeatureBackground(state, background){
        state.epic_data.background = background;
    },
    addNewActor(state, actor_data){
        state.epic_data.epic_table.push(actor_data);
    },
    editActor(state, actor_data){
        var actor_index = state.epic_data.epic_table.findIndex((actor => actor.actor_id == actor_data.actor_id));
        state.epic_data.epic_table.splice(actor_index, 1, actor_data);
    },
    removeActor(state, actorId) {
        state.epic_data.epic_table = state.epic_data.epic_table.filter(epic_table => epic_table.actor_id !== actorId);
    },
    addEpicTolist(state, epic_data){
        epic_data.epic_module = [];
        state.epic_data.get_epic.push(epic_data);
    },
    addBreakdownToList(state, breakdown_data){
        var epic_index = state.epic_data.get_epic.findIndex((epic => epic.epic_id == breakdown_data.epic_id));
        state.epic_data.get_epic[epic_index].epic_module.push(breakdown_data);
    },
    editBreakdownList(state, breakdown_data){
        var epic_index = state.epic_data.get_epic.findIndex((epic => epic.epic_id == breakdown_data.epic_id));
        var breakdown_index = state.epic_data.get_epic[epic_index].epic_module.findIndex((breakdown => breakdown.epb_id == breakdown_data.epb_id));
        state.epic_data.get_epic[epic_index].epic_module.splice(breakdown_index, 1, breakdown_data);
    },
    updateEpicList(state, epic_data){
        var epic_index = state.epic_data.get_epic.findIndex((epic => epic.epic_id == epic_data.epic_id));
        state.epic_data.get_epic[epic_index].title = epic_data.title;
        state.epic_data.get_epic[epic_index].description = epic_data.description;
        state.epic_data.get_epic[epic_index].out_scope = epic_data.out_scope;
        state.epic_data.get_epic[epic_index].assumption = epic_data.assumption;
        state.epic_data.get_epic[epic_index].risks = epic_data.risks;
    },
    removeFromEpicList(state, epicId){
        state.epic_data.get_epic = state.epic_data.get_epic.filter(epic => epic.epic_id !== epicId);
    },
    removeBreakdown(state, payload){
        var epic_index = state.epic_data.get_epic.findIndex((epic => epic.epic_id == payload.epicId));

        if (epic_index > -1) {
            state.epic_data.get_epic[epic_index].epic_module = state.epic_data.get_epic[epic_index].epic_module.filter(breakdown => breakdown.epb_id !== payload.breakdownId);
        }
    }
};

const actions = {
    saveEpicBackground(context, backgroundArguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/epic-feature', {...backgroundArguments}).then(({data})=>{
                // console.log(data);
                resolve(data);
            })
            .catch(({response}) => {
                //console.log(response);
                reject(response);
            });
        });
    },
    fetchEpicScopeData(context, scope_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/epic-scope-data', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setEpicData', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    fetchExistingEpicScopeData(context) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/component-list', `?type=${COMPONENT_TYPES[0]}&per_page=-1`).then(({data})=>{
                context.commit('setExistingEpicData', data.success);
                resolve(data);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    },
    saveEpicActor(context, actor_data){
        if(typeof actor_data.actor_id != 'undefined'){
            return new Promise ((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/epic-table-update', {...actor_data})
                .then(({data})=>{
                    context.commit('editActor', data.success);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
            });
        } else {
            return new Promise ((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/epic-table-save', {...actor_data})
                .then(({data})=>{
                    context.commit('addNewActor', data.success);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
            });
        }
    },
    deleteEpicActor(context, actorId){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/epic-table-delete', {actor_id: actorId})
            .then(({data})=>{
                context.commit('removeActor', actorId);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    saveEpic(context, epic_data){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/epic-save', {...epic_data})
            .then(({data})=>{
                context.commit('addEpicTolist', data.success);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    epicUpdate(context, epic_data){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/epic-update', {...epic_data})
            .then(({data})=>{
                context.commit('updateEpicList', data.success);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    deleteEpic(context, epicId){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/epic-delete', {epic_id: epicId})
            .then(({data})=>{
                context.commit('removeFromEpicList', epicId);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    saveEpicBreakdown(context, breakdown_data){
        if(typeof breakdown_data.epb_id != 'undefined'){
            return new Promise ((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/epic-breakdown-update', {...breakdown_data})
                .then(({data})=>{
                    context.commit('editBreakdownList', data.success);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
            });
        } else {
            return new Promise ((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/epic-breakdown-save', {...breakdown_data})
                .then(({data})=>{
                    context.commit('addBreakdownToList', data.success);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
            });
        }
    },
    deleteEpicBreakdown(context, payload){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/epic-breakdown-delete', {breakdown_id: payload.breakdownId})
            .then(({data})=>{
                context.commit('removeBreakdown', payload);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};