<template>
  <Layout>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea illum, ex expedita corporis minus adipisci quidem exercitationem hic voluptatem, voluptate, doloribus numquam? Nobis dicta, provident quas vel laboriosam accusantium modi.
  </Layout>
</template>

<script>
import Layout from '@/shared/Layout';
export default {
  components: {
    Layout
  }
}
</script>

<style>

</style>